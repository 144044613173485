import React from 'react';
// import HeroStyle6 from '../Hero/HeroStyle6';
import Spacing from '../Spacing';
// import Button from '../Button';
import SectionHeading from '../SectionHeading';
import T_C from '../Accordion/T_C';
// import Cta from '../Cta';
import { pageTitle } from '../../helpers/PageTitle';

// const faqData = [
//   {
//     title: '01. How can I contact you for your services?',
//     content:
//       'You can reach us via email at connect@opeyn.com for any inquiries or to discuss your project requirements. We look forward to helping you achieve your digital goals.',
//   },
//   {
//     title: '02. What types of services do you provide?',
//     content:
//       'We offer a range of services including Custom Web Development, Mobile App Development, Branding, Social Ad Campaigns, UI/UX Design, and SEO & Digital Marketing. Our comprehensive solutions are tailored to meet your specific business needs.',
//   },
//   {
//     title: '03. What are the different stages of the working process?',
//     content:
//       'Our process includes an initial consultation to understand your needs, followed by planning and strategy development, design and development, testing and quality assurance, and finally, deployment and ongoing support. We ensure each stage is carefully managed to deliver the best results.',
//   },
//   {
//     title: '04. What is the difference between web development and mobile app development?',
//     content:
//       'Web development focuses on creating websites that are accessible via internet browsers, whereas mobile app development involves creating applications specifically designed for mobile devices. Both services aim to provide seamless user experiences, but they cater to different platforms and user interactions.',
//   },
//   {
//     title: '05. How can I proceed with payment after the project is completed?',
//     content:
//       'Once your project is completed to your satisfaction, we will provide you with a detailed invoice. Payment can be made through various methods including bank transfer, credit card, or other electronic payment options. We ensure a smooth and secure payment process.',
//   },
// ];

const termsData = [
    {
        title: "Project Estimates and Changes",
        points: [
            "All estimates/quotes are based on our understanding of your requirements and the provided time-frame. Any changes, including minor improvements, may incur additional costs. Please clarify our understanding in a meeting.",
            "By accepting a quote, you agree to and accept the terms and conditions of OPEYN. Acceptance can be verbal, by email, payment of initiation, or signing a quote.",
            "Clients must ensure that all requirements are included in the quotes/proposals/estimates and that we fully understand their needs. Clear guidelines must be provided. Any discrepancy due to unclear requirements may lead to additional costs.",
            "Complexity related to specific tasks must be advised in advance and included in the proposal for costing purposes. Any discrepancy due to unclear requirements will not be borne by OPEYN."
        ]
    },
    {
        title: "Man-Hours and Project Management",
        points: [
            "Limited man-hours are allocated to each task, including project management and digital strategy/business analysis. Minor changes may be included within the allocated hours and analyzed on a case-by-case basis.",
            "OPEYN will make every effort to complete the project/changes within the given timeframe. Reasonable delays are accepted if functionalities are redefined or modified.",
            "Client delays may extend the project and proposed timeframes, possibly incurring additional costs."
        ]
    },
    {
        title: "Bugs, Re-Work, and Modifications",
        points: [
            "Programming errors reported during or just after development do not incur additional charges.",
            "Re-work on an already completed task or changes in the design after approval will incur additional charges.",
            "Modifications requested during development or after Go-Live approval will incur additional charges. Additional work beyond the estimates is charged separately."
        ]
    },
    {
        title: "Content and Testing",
        points: [
            "Website/application content and all related materials must be provided within the first two weeks of starting the project. Delays may lead to additional costs.",
            "Our websites/applications are generally tested on PCs and recent versions of IE, Firefox, Chrome, and Safari. Testing on other browsers should be requested in advance.",
            "Responsive/multi-device compatible web pages are tested on Android and iOS devices. Testing on other devices should be discussed in advance."
        ]
    },
    {
        title: "Hosting and Server Issues",
        points: [
            "If your website/application is not hosted on an OPEYN server, additional man-hours required due to server or network issues are not covered in our quotes and may be charged separately.",
            "Stalling the project for over two calendar months will incur ₹8,000/week administration costs and may increase previously approved estimates.",
            "OPEYN software codes are proprietary. Codes can be handed over at an additional cost, subject to conditions. OPEYN CMS cannot be transferred to any external host."
        ]
    },
    {
        title: "Third-Party Components and Open Source",
        points: [
            "There may be third-party components like payment gateways or SSL certificates involved. Any limitations are beyond our control, and costs are not included in our quotes.",
            "OPEYN takes no responsibility for open-source products like WordPress. Clients must update all components and take regular backups.",
            "Packages may be affected by changes to third-party providers' rules and policies."
        ]
    },
    {
        title: "Payment Terms",
        points: [
            "All prices are quoted in Indian Rupees inclusive of GST unless specified.",
            "Invoices must be processed as per the schedule via the preferred method of payment.",
            "Milestone payments must be made within 5 working days of each milestone. Final payment must be made before going live.",
            "Receipt of payment is deemed as acceptance of the quote, milestone, delivery, project, and terms & conditions.",
            "Late fees and charges: Outstanding invoices incur a late payment fee of 10% of the pending amount and an administration fee of ₹450 per month."
        ]
    },
    {
        title: "Hosting, Domains, and Emails",
        points: [
            "Hosting is an annual charge paid in advance. Delays in renewal can lead to loss of files and data.",
            "Domains must be renewed in time to avoid service disruption. Payments must be made 7 working days before the expiry date.",
            "All emails are to be downloaded periodically and must be kept within quota limits."
        ]
    },
    {
        title: "Intellectual Property",
        points: [
            "OPEYN reserves the right to change the content or policies without notice.",
            "Project-related copyrights can be transferred to the client at an additional cost, after full payment and signing the Certificate of Acceptance.",
            "OPEYN software codes and OPEYN CMS are proprietary and cannot be transferred or resold."
        ]
    },
    {
        title: "Disputes and Liability",
        points: [
            "In case of a dispute, OPEYN reserves the right to charge the client for the work done and resources spent.",
            "OPEYN provides services without guarantees on security. We are not liable for data loss or disruptions.",
            "Clients will indemnify and hold OPEYN harmless from any claims arising from the use of the information provided by OPEYN."
        ]
    },
    {
        title: "Digital Marketing and Annual Maintenance",
        points: [
            "Digital Marketing packages are for a minimum of 6 months and billed monthly. They require a one-month email notice for cancellation.",
            "Maintenance packages are for a minimum of 12 months, billed annually, and include basic website hosting. Unused hours do not roll over."
        ]
    },
    {
        title: "General Approach to Development",
        points: [
            "Upon quote acceptance and initiation payment, the project starts. A Scoping Workshop may be held if required.",
            "Design mock-ups are reviewed and modified within the given estimates. Finalized designs proceed to HTML development.",
            "CMS setup requires all content and copy prior to development. Content transfer is done as much as possible based on allocated resources.",
            "A Go-Live date must be provided once the site is ready for review. The site goes live within 3 to 5 working days after Go-Live approval.",
            "Regular backups and updates of all components are the client's responsibility after the website/application goes live."
        ]
    }
];

export default function TermsConditionPage() {
    pageTitle('Home');
    return (
        <>
            {/* This area below is Hero Section */}
            {/* <HeroStyle6
        title="Terms & Conditions"
        subTitle="Harness the limitless power of web and app development to transcend traditional boundaries and create exceptional digital experiences. Our expertise in crafting innovative websites and applications captivates users, driving engagement and delivering seamless functionality. Elevate your brand with cutting-edge technology, ensuring your digital presence stands out and leaves a lasting impression."
        btnText="Contact us"
        btnUrl="/contact"
      /> */}

            {/* This area below is FAQs */}
            <section>
                <Spacing lg="140" md="40" />
                <div className="container">
                    <SectionHeading title="Terms & Conditions" subTitle="T&C" />
                    <Spacing lg="55" md="30" />
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1">
                            <T_C variant="cs_type_1" data={termsData} />
                        </div>
                    </div>
                </div>
                <Spacing lg="120" md="50" />
            </section>
        </>
    );
}
