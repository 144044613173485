import React from 'react';
import PricingTable from '.';

export default function PricingTableListApp() {
  return (
    <div className="cs_tabs cs_style1">
      <div className="row cs_gap_y_30">
        {/* <div className="col-lg-4">
          <PricingTable
            title="Basic"
            subTitle="Launch Your App Quickly with Essential Features."
            originalPrice="80,000"
            discountedPrice="40,000"
            currency="Rs "
            features={[
              'Native App Development',
              'Basic UI/UX Design',
              'Standard Security Features',
              'App Store Submission',
              'Push Notifications',
              'Basic Analytics Setup',
              'Email Support'
            ]}
            btnText="Choose Package"
            btnLink="/"
          />
        </div>
        <div className="col-lg-4">
          <PricingTable
            title="Pro"
            subTitle="Accelerate Your Growth with Advanced App Features."
            originalPrice="150,000"
            discountedPrice="75,000"
            currency="Rs "
            features={[
              'Custom App Development',
              'Advanced UI/UX Design',
              'Enhanced Security Features',
              'App Store & Play Store Submission',
              'In-App Purchases',
              'Advanced Analytics',
              'Priority Email and Phone Support',
              'Monthly Performance Reports'
            ]}
            btnText="Choose Package"
            btnLink="/"
            popular
          />
        </div> */}
        <div className="col-lg-4">
          <PricingTable
            title="Custom"
            subTitle="Dominate the Market with a Fully Customized App Solution."
            originalPrice=""
            discountedPrice="Get Your Price*"
            currency=""
            features={[
              'Bespoke App Development',
              'Complete UI/UX Customization',
              'Top-Tier Security Features',
              'Comprehensive App Store Management',
              'Advanced Integrations (API, CRM, ERP)',
              'Custom Analytics and Business Intelligence',
              'Dedicated Account Manager',
              '24/7 Premium Support',
              'Monthly Strategy Meetings'
            ]}
            btnText="Contact for more info"
            btnLink="/contact"
          />
        </div>
      </div>
    </div>
  );
}
