import { Icon } from '@iconify/react';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const addressDataList = [
  {
    title: 'Bangalore, India',
    href: '#',
  },
  {
    title: 'A brand of Sailak Solutions Pvt. Ltd',
    href: '#',
  },
  {
    title: 'Whatsapp: +918099182490',
    href: 'https://wa.me/+918099182490?text=Hi',
  },
  // {
  //   title: 'Phone: +918099182490',
  //   href: 'tel:+918099182490',
  // },
  {
    title: 'Email: connect@opeyn.com',
    href: 'mailto:connect@opeyn.com',
  },
];

const serviceMenuList = [
  {
    title: 'Web Development',
    href: '/service/web',
  },
  {
    title: 'Mobile App Development',
    href: '/service/app',
  },
  {
    title: 'SEO and Digital Marketing',
    href: '/service/seo',
  },
];

const LinksMenuList = [
  {
    title: 'Home',
    href: '/',
  },
  {
    title: 'About',
    href: '/about',
  },
  {
    title: 'Services',
    href: '/service',
  },
  {
    title: 'Contact',
    href: '/contact',
  },
];

const socialBtnList = [
  {
    icon: 'fa6-brands:linkedin-in',
    href: 'https://www.linkedin.com/company/opeyn/',
  },
  {
    icon: 'fa6-brands:twitter',
    href: '/',
  },
  {
    icon: 'fa6-brands:youtube',
    href: '/',
  },
  {
    icon: 'fa6-brands:facebook-f',
    href: '/',
  },
  {
    icon: 'fa6-brands:instagram',
    href: 'https://www.instagram.com/opeyn_marketing/',
  },
];

export default function Footer() {
  const [email, setEmail] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [isAlertVisible, setIsAlertVisible] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const formEle = document.querySelector(".cs_newsletter_form");
    const formData = new FormData(formEle);
    // formData.append('formType', 'newsletter');
    try {
      const response = await fetch(`https://script.google.com/macros/s/${process.env.REACT_APP_SCRIPT_ID}/exec?sheetName=Footer_newsletter`, { // Replace with your Apps Script Web App URL
        method: 'POST',
        body: formData
      });
      const result = await response.json();
      setAlertMessage(result.message);
      setIsAlertVisible(true);
    } catch (error) {
      setAlertMessage('Something went wrong');
      setIsAlertVisible(true);
    }
  };

  const closeAlert = () => {
    setIsAlertVisible(false);
    window.location.reload(); // Refresh the page after closing the alert
  };

  return (
    <footer className="cs_fooer cs_bg_filed" style={{ backgroundImage: 'url(/images/footer_bg.jpeg)' }}>
      <div className="cs_fooer_main">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="cs_footer_item">
                <div className="cs_text_widget">
                  <img src="/images/logo_white.png" alt="Logo" width={"75%"} />
                </div>
                <ul className="cs_menu_widget cs_mp0">
                  {addressDataList.map((item, index) => (
                    <li key={index}>
                      <Link to={item.href} target="_blank" rel="noopener noreferrer">{item.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="cs_footer_item">
                <h2 className="cs_widget_title">Services</h2>
                <ul className="cs_menu_widget cs_mp0">
                  {serviceMenuList.map((item, index) => (
                    <li key={index}>
                      <Link to={item.href}>{item.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="cs_footer_item">
                <h2 className="cs_widget_title">Links</h2>
                <ul className="cs_menu_widget cs_mp0">
                  {LinksMenuList.map((item, index) => (
                    <li key={index}>
                      <Link to={item.href}>{item.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="cs_footer_item">
                <h2 className="cs_widget_title">Subscribe Newsletter</h2>
                <div className="cs_newsletter cs_style_1">
                  <div className="cs_newsletter_text">
                    We make sure to only send emails that are noteworthy and pertinent to the recipient.
                  </div>
                  <form onSubmit={handleFormSubmit} className="cs_newsletter_form">
                    <input
                      type="email"
                      className="cs_newsletter_input"
                      placeholder="Email address"
                      required
                      value={email}
                      onChange={handleEmailChange}
                      name="email"
                    />
                    <button className="cs_btn cs_style_1" type="submit">
                      Submit
                      <span>
                        <i>
                          <Icon icon="fa6-solid:arrow-right" />
                        </i>
                        <i>
                          <Icon icon="fa6-solid:arrow-right" />
                        </i>
                      </span>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="cs_bottom_footer">
          <div className="cs_bottom_footer_left">
            <div className="cs_social_btns cs_style_1">
              {socialBtnList.map((item, index) => (
                <Link to={item.href} className="cs_center" key={index}>
                  <Icon icon={item.icon} />
                </Link>
              ))}
            </div>
          </div>
          <div className="cs_copyright">Copyright © 2024 Opeyn.</div>
          <div className="cs_bottom_footer_right"><Link to="/privacypolicy">Privacy Policy</Link></div>
        </div>
      </div>
      {isAlertVisible && (
        <div className="alert-overlay">
          <div className="alert-content">
            <p>{alertMessage}</p>
            <button className="close-btn" onClick={closeAlert}>
              <Icon icon="fa6-solid:xmark" />
            </button>
          </div>
        </div>
      )}
    </footer>
  );
}
