import React from 'react';
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#333',
    color: '#fff',
    border: 'none',
    borderRadius: '10px',
    padding: '20px',
    textAlign: 'center',
    zIndex: 1001, // Ensure this is higher than any other zIndex on the page
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    zIndex: 1000, // Ensure this is lower or equal to content's zIndex
  },
};

const AlertModal = ({ isOpen, onRequestClose, message }) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    style={customStyles}
    contentLabel="Alert Modal"
    ariaHideApp={false}
  >
    <div>
      <p>{message}</p>
      <button className="cs_btn cs_style_1" type="submit" onClick={onRequestClose}>
        Close
      </button>
    </div>
  </Modal>
);

export default AlertModal;
