import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'swiper/css';
import 'slick-carousel/slick/slick.css';
import './sass/index.scss';
import posthog from 'posthog-js'

if (!window.location.host.includes('127.0.0.1') && !window.location.host.includes('localhost')) {
  posthog.init('phc_qM6CndyvpCakCwhu4jTQ5ZlgBRfi4A7o1YeqzLC0bwG', { api_host: 'https://us.i.posthog.com', person_profiles: 'always' })
}

// posthog.init('phc_qM6CndyvpCakCwhu4jTQ5ZlgBRfi4A7o1YeqzLC0bwG',
//   {
//     api_host: 'https://us.i.posthog.com',
//     person_profiles: 'always' // or 'always' to create profiles for anonymous users as well
//     // person_profiles: 'identified_only' // or 'always' to create profiles for anonymous users as well
//   }
// )

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
);
