import React, { useState } from 'react';
import Spacing from '../Spacing';
import SectionHeadingStyle3 from '../SectionHeading/SectionHeadingStyle3';
import { pageTitle } from '../../helpers/PageTitle';
import { Icon } from '@iconify/react';
import AlertModal from '../AlertModal'; // Adjust the import path as necessary

export default function ContactPage() {
  pageTitle('Contact');

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formEle = document.querySelector(".cs_contact_form");
    const formData = new FormData(formEle);
    try {
      const response = await fetch(`https://script.google.com/macros/s/${process.env.REACT_APP_SCRIPT_ID}/exec?sheetName=Contact_Form`, { // Replace with your Apps Script Web App URL
        method: 'POST',
        body: formData
      });
      const result = await response.json();
      setModalMessage(result.message);
      setIsModalOpen(true);
    } catch (error) {
      setModalMessage('Something went wrong');
      setIsModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    window.location.reload(); // Refresh the page after closing the modal
  };

  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />

      {/* Hero Section */}
      <SectionHeadingStyle3
        title="Get in touch for more <br/>information and support"
        subTitle="Contact"
        shape="shape_6"
      />
      <Spacing lg="75" md="60" />

      {/* Form Section */}
      <section>
        <div className="container">
          <div className="row align-items-center cs_gap_y_45">
            <div className="col-lg-6">
              <h2 className="cs_fs_50">
                Come & visit <br />
                our place!
              </h2>
              <div className="cs_height_55 cs_height_lg_30" />
              <ul className="cs_mp0 cs_contact_info">
                <li>
                  <h3 className="cs_fs_29 cs_semibold">Email:</h3>
                  <p className="mb-0"><a href='mailto:connect@opeyn.com' target="_blank" rel="noopener noreferrer">connect@opeyn.com</a></p>
                </li>
                <li>
                  <h3 className="cs_fs_29 cs_semibold">Phone:</h3>
                  <p className="mb-0"><a href='tel:+918099182490' target="_blank" rel="noopener noreferrer">+918099182490</a></p>
                </li>
                <li>
                  <h3 className="cs_fs_29 cs_semibold">WhatsApp:</h3>
                  <p className="mb-0"><a href='https://wa.me/+918099182490?text=Hi' target="_blank" rel="noopener noreferrer">+918099182490</a></p>
                </li>
                <li>
                  <h3 className="cs_fs_29 cs_semibold">Address:</h3>
                  <p className="mb-0">Bangalore, India</p>
                </li>
              </ul>
            </div>
            <div className="col-lg-6">
              <div className="cs_contact_form_wrap">
                <div className="cs_gray_bg_3 cs_contact_form_bg" />
                <h2 className="cs_fs_40 cs_semibold" style={{ color: '#df663b' }}>Book an Appointment</h2>
                <form className="cs_contact_form" onSubmit={handleSubmit}>
                  <label className="cs_fs_21 cs_semibold cs_primary_color">
                    Your full name
                  </label>
                  <input
                    name="name"
                    placeholder="Type your name"
                    type="text"
                    required
                    className="cs_form_field"
                    value={formData.name}
                    onChange={handleChange}
                  />
                  <div className="cs_height_38 cs_height_lg_25" />
                  <label className="cs_fs_21 cs_semibold cs_primary_color">
                    Email
                  </label>
                  <input
                    name="email"
                    placeholder="Type your email address"
                    type="text"
                    required
                    className="cs_form_field"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  <div className="cs_height_38 cs_height_lg_25" />
                  <label className="cs_fs_21 cs_semibold cs_primary_color">
                    Phone number
                  </label>
                  <input
                    name="phone"
                    placeholder="Type your phone number (optional)"
                    type="text"
                    className="cs_form_field"
                    value={formData.phone}
                    onChange={handleChange}
                  />
                  <div className="cs_height_38 cs_height_lg_25" />
                  <label className="cs_fs_21 cs_semibold cs_primary_color" hidden>
                    Your Message
                  </label>
                  <textarea
                    hidden
                    name="message"
                    cols={30}
                    rows={5}
                    className="cs_form_field"
                    value={formData.message}
                    onChange={handleChange}
                  />
                  <div className="cs_height_38 cs_height_lg_25" />
                  <button className="cs_btn cs_style_1" type="submit">
                    Learn More{' '}
                    <span>
                      <i>
                        <Icon icon="fa6-solid:arrow-right" />
                      </i>
                      <i>
                        <Icon icon="fa6-solid:arrow-right" />
                      </i>
                    </span>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="cs_height_0 cs_height_lg_80" />
      </section>

      {/* Alert Modal */}
      <AlertModal
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        message={modalMessage}
      />

      {/* This area below is Calender */}
      {/* <div className="cs_map">
        <iframe
          id="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d96652.27317354927!2d-74.33557928194516!3d40.79756494697628!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c3a82f1352d0dd%3A0x81d4f72c4435aab5!2sTroy+Meadows+Wetlands!5e0!3m2!1sen!2sbd!4v1563075599994!5m2!1sen!2sbd"
          allowFullScreen
          title="Google Map"
        />
      </div> */}
    </>
  );
}
