import React from 'react';
import Spacing from '../Spacing';
import SectionHeadingStyle3 from '../SectionHeading/SectionHeadingStyle3';
import CtaStyle2 from '../Cta/CtaStyle2';
import AboutStyle6 from '../About/AboutStyle6';
import CardStyle3 from '../Card/CardStyle3';
import { pageTitle } from '../../helpers/PageTitle';

export default function ServiceDetailsPageApp() {
  pageTitle('App Development');
  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />

      {/* Hero Section */}
      <SectionHeadingStyle3
        title="Building seamless and high-performing mobile applications"
        subTitle="App Development"
        shape="shape_3"
      />
      <Spacing lg="75" md="60" />

      {/* About Section */}
      <div className="cs_service_info">
        <div className="container">
          <div className="row align-items-center cs_gap_y_40">
            <div className="col-lg-6">
              <div
                className="cs_service_info_thumb cs_bg_filed"
                style={{
                  backgroundImage:
                    'url("/images/others/app3.jpg")',
                }}
              />
            </div>
            <div className="col-lg-6">
              <div className="row cs_gap_y_40">
                <div className="col-sm-6">
                  <CardStyle3
                    number="01"
                    title="iOS Development"
                    subTitle="Building robust and user-friendly iOS applications."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="02"
                    title="Android Development"
                    subTitle="Creating high-performance Android applications."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="03"
                    title="Cross-Platform Development"
                    subTitle="Developing apps that work seamlessly across iOS and Android."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="04"
                    title="UI/UX Design"
                    subTitle="Designing intuitive and engaging user interfaces for mobile apps."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="05"
                    title="App Testing"
                    subTitle="Ensuring high quality and performance through rigorous testing."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="06"
                    title="App Maintenance"
                    subTitle="Providing ongoing support and updates to keep your app running smoothly."
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Spacing lg="150" md="80" />
      <AboutStyle6
        thumbnailSrc="/images/others/app4.jpg"
        title="What you will get from this service?"
        subTitle="Our app development services focus on creating seamless and high-performing mobile applications. From iOS and Android development to UI/UX design and app maintenance, we ensure your app stands out in the crowded marketplace."
        featureList={[
          'Robust iOS and Android development',
          'Cross-platform compatibility',
          'Intuitive UI/UX design',
          'Comprehensive app testing and maintenance',
        ]}
        btnText2="Explore Web Development"
        btnUrl2="/service/web"
        btnText3="Explore UI/UX Design"
        btnUrl3="/service/uiux"
        btnText="More"
        btnUrl="/service"
      />
      <Spacing lg="50" md="80" />

      {/* CTA Section */}
      <div className="cs_height_140 cs_height_lg_70" />
      <CtaStyle2
        title="Is there a specific project or goal <br />that you have in mind?"
        btnText="Send Message"
        btnUrl="/contact"
      />
      <div className="cs_height_150 cs_height_lg_80" />
    </>
  );
}
