import React from 'react';
import Spacing from '../Spacing';
import SectionHeadingStyle3 from '../SectionHeading/SectionHeadingStyle3';
import CtaStyle2 from '../Cta/CtaStyle2';
import AboutStyle6 from '../About/AboutStyle6';
import CardStyle3 from '../Card/CardStyle3';
import { pageTitle } from '../../helpers/PageTitle';

export default function ServiceDetailsPageOptimization() {
  pageTitle('On Page Optimization');
  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />

      {/* Hero Section */}
      <SectionHeadingStyle3
        title="Enhancing your website's visibility and user experience"
        subTitle="On Page Optimization"
        shape="shape_3"
      />
      <Spacing lg="75" md="60" />

      {/* About Section */}
      <div className="cs_service_info">
        <div className="container">
          <div className="row align-items-center cs_gap_y_40">
            <div className="col-lg-6">
              <div
                className="cs_service_info_thumb cs_bg_filed"
                style={{
                  backgroundImage:
                    'url("/images/others/optimization.jpg")',
                }}
              />
            </div>
            <div className="col-lg-6">
              <div className="row cs_gap_y_40">
                <div className="col-sm-6">
                  <CardStyle3
                    number="01"
                    title="Keyword Research"
                    subTitle="Identifying the most effective keywords to target your audience."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="02"
                    title="Content Optimization"
                    subTitle="Optimizing your website's content to improve search engine rankings."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="03"
                    title="Meta Tags"
                    subTitle="Creating and optimizing meta tags to enhance search engine visibility."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="04"
                    title="Internal Linking"
                    subTitle="Building a robust internal linking structure to improve navigation and SEO."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="05"
                    title="Image Optimization"
                    subTitle="Optimizing images to improve load speed and search engine ranking."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="06"
                    title="Page Speed Optimization"
                    subTitle="Enhancing your website's load speed for better user experience and SEO."
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Spacing lg="150" md="80" />
      <AboutStyle6
        thumbnailSrc="/images/others/optimization2.jpg"
        title="What you will get from this service?"
        subTitle="Our on page optimization services focus on improving your website's visibility and user experience. We employ strategic planning and precise execution to enhance various elements of your site, ensuring it ranks higher in search results."
        featureList={[
          'Effective keyword research',
          'Content and meta tag optimization',
          'Improved internal linking structure',
          'Faster page load speeds',
        ]}
        btnText2="Explore SEO Services"
        btnUrl2="/service/seo"
        btnText3="Explore Web Development"
        btnUrl3="/service/web"
        btnText="More"
        btnUrl="/service"
      />
      <Spacing lg="50" md="80" />

      {/* CTA Section */}
      <div className="cs_height_140 cs_height_lg_70" />
      <CtaStyle2
        title="Is there a specific project or goal <br />that you have in mind?"
        btnText="Send Message"
        btnUrl="/contact"
      />
      <div className="cs_height_150 cs_height_lg_80" />
    </>
  );
}
