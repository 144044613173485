import React from 'react';
import Spacing from '../Spacing';
import SectionHeadingStyle3 from '../SectionHeading/SectionHeadingStyle3';
import CtaStyle2 from '../Cta/CtaStyle2';
import AboutStyle6 from '../About/AboutStyle6';
import CardStyle3 from '../Card/CardStyle3';
import { pageTitle } from '../../helpers/PageTitle';

export default function ServiceDetailsPageInfluencer() {
  pageTitle('Influencer Marketing');
  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />

      {/* Hero Section */}
      <SectionHeadingStyle3
        title="Leverage the power of influencers to boost your brand"
        subTitle="Influencer Marketing"
        shape="shape_3"
      />
      <Spacing lg="75" md="60" />

      {/* About Section */}
      <div className="cs_service_info">
        <div className="container">
          <div className="row align-items-center cs_gap_y_40">
            <div className="col-lg-6">
              <div
                className="cs_service_info_thumb cs_bg_filed"
                style={{
                  backgroundImage:
                    'url("/images/others/influencer.jpg")',
                }}
              />
            </div>
            <div className="col-lg-6">
              <div className="row cs_gap_y_40">
                <div className="col-sm-6">
                  <CardStyle3
                    number="01"
                    title="Influencer Identification"
                    subTitle="Finding the right influencers who align with your brand and audience."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="02"
                    title="Campaign Strategy"
                    subTitle="Developing a comprehensive strategy to maximize influencer impact."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="03"
                    title="Content Creation"
                    subTitle="Collaborating with influencers to create authentic and engaging content."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="04"
                    title="Campaign Management"
                    subTitle="Managing the entire campaign process, from planning to execution."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="05"
                    title="Performance Tracking"
                    subTitle="Monitoring and analyzing the performance of influencer campaigns."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="06"
                    title="Reporting"
                    subTitle="Providing detailed reports on campaign outcomes and ROI."
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Spacing lg="150" md="80" />
      <AboutStyle6
        thumbnailSrc="/images/others/influencer2.webp"
        title="What you will get from this service?"
        subTitle="Our influencer marketing services help you leverage the power of influencers to boost your brand visibility and engagement. We manage everything from influencer identification to campaign execution and performance tracking."
        featureList={[
          'Identifying the right influencers',
          'Comprehensive campaign strategy',
          'Authentic content creation',
          'Detailed performance tracking and reporting',
        ]}
        btnText2="Explore Social Media Marketing"
        btnUrl2="/service/social"
        btnText3="Explore Branding Design"
        btnUrl3="/service/branding"
        btnText="More"
        btnUrl="/service"
      />
      <Spacing lg="50" md="80" />

      {/* CTA Section */}
      <div className="cs_height_140 cs_height_lg_70" />
      <CtaStyle2
        title="Is there a specific project or goal <br />that you have in mind?"
        btnText="Send Message"
        btnUrl="/contact"
      />
      <div className="cs_height_150 cs_height_lg_80" />
    </>
  );
}
