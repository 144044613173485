import React from 'react';
import PricingTable from '.';

export default function PricingTableListWeb() {
  return (
    <div className="cs_tabs cs_style1">
      <div className="row cs_gap_y_30">
        <div className="col-lg-4">
          <PricingTable
            title="Basic"
            subTitle="Get Online Fast with a Complete Set of Basic Web Essentials."
            originalPrice="60,000"
            discountedPrice="30,000*"
            currency="Rs "
            features={[
              'Coded Website',
              'Responsive Design',
              'Basic SEO Setup',
              'Hosting Setup',
              'Enhanced Security Features',
              'Contact Form Integration',
              'Basic Analytics Setup',
              'Email Support'
            ]}
            btnText="Contact for more info"
            btnLink="/contact"
          />
        </div>
        <div className="col-lg-4">
          <PricingTable
            title="Pro"
            subTitle="Drive Growth with Professional Web Solutions and Enhanced SEO."
            originalPrice="100,000"
            discountedPrice="50,000*"
            currency="Rs "
            features={[
              'Custom Web Design',
              'Coded Website',
              'Advanced SEO Features',
              'E-Commerce Integration',
              'Advanced Hosting Setup',
              'Enhanced Security Features',
              'DDoS Attack Prevention',
              'Multiple Contact Forms',
              'Advanced Analytics',
              'Priority Email and Phone Support',
              'Monthly Performance Reports'
            ]}
            btnText="Contact for more info"
            btnLink="/contact"
            popular
          />
        </div>
        <div className="col-lg-4">
          <PricingTable
            title="Custom"
            subTitle="Lead the Market with Cutting-Edge Development and Strategic Insights."
            originalPrice=""
            discountedPrice="Get Your Price*"
            currency=""
            features={[
              'Custom Web Application Development',
              'Coded Website',
              'Full SEO Optimization',
              'Advanced E-Commerce Features',
              'Dedicated Hosting and Maintenance',
              'Comprehensive Security Suite',
              'Integration with CRM/ERP',
              'Custom Analytics and Business Intelligence',
              'Dedicated Account Manager',
              '24/7 Premium Support',
              'Monthly Strategy Meetings'
            ]}
            btnText="Contact for more info"
            btnLink="/contact"
          />
        </div>
      </div>
    </div>
  );
}
