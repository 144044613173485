import React from 'react';
import Spacing from '../Spacing';
import SectionHeadingStyle3 from '../SectionHeading/SectionHeadingStyle3';
import CtaStyle2 from '../Cta/CtaStyle2';
import AboutStyle6 from '../About/AboutStyle6';
import CardStyle3 from '../Card/CardStyle3';
import { pageTitle } from '../../helpers/PageTitle';

export default function ServiceDetailsPageSocial() {
  pageTitle('Social Media Marketing');
  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />

      {/* Hero Section */}
      <SectionHeadingStyle3
        title="Boost your online presence with effective social media strategies"
        subTitle="Social Media Marketing"
        shape="shape_3"
      />
      <Spacing lg="75" md="60" />

      {/* About Section */}
      <div className="cs_service_info">
        <div className="container">
          <div className="row align-items-center cs_gap_y_40">
            <div className="col-lg-6">
              <div
                className="cs_service_info_thumb cs_bg_filed"
                style={{
                  backgroundImage:
                    'url("/images/others/social.jpg")',
                }}
              />
            </div>
            <div className="col-lg-6">
              <div className="row cs_gap_y_40">
                <div className="col-sm-6">
                  <CardStyle3
                    number="01"
                    title="Social Media Strategy"
                    subTitle="Developing a customized strategy to meet your business goals."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="02"
                    title="Content Creation"
                    subTitle="Creating engaging content tailored for various social media platforms."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="03"
                    title="Community Management"
                    subTitle="Managing and growing your online community through active engagement."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="04"
                    title="Paid Social Advertising"
                    subTitle="Running targeted ad campaigns to reach a wider audience."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="05"
                    title="Analytics and Reporting"
                    subTitle="Tracking and analyzing social media performance to optimize strategies."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="06"
                    title="Influencer Collaboration"
                    subTitle="Partnering with influencers to amplify your brand's reach."
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Spacing lg="150" md="80" />
      <AboutStyle6
        thumbnailSrc="/images/others/social2.jpg"
        title="What you will get from this service?"
        subTitle="Our social media marketing services are designed to enhance your online presence and engage your audience effectively. We provide a comprehensive approach to social media management, ensuring your brand stands out in the digital space."
        featureList={[
          'Customized social media strategy',
          'Engaging content creation',
          'Active community management',
          'Detailed analytics and reporting',
        ]}
        btnText2="Explore Influencer Marketing"
        btnUrl2="/service/influencer"
        btnText3="Explore Content Creation"
        btnUrl3="/service/content"
        btnText="More"
        btnUrl="/service"
      />
      <Spacing lg="50" md="80" />

      {/* CTA Section */}
      <div className="cs_height_140 cs_height_lg_70" />
      <CtaStyle2
        title="Is there a specific project or goal <br />that you have in mind?"
        btnText="Send Message"
        btnUrl="/contact"
      />
      <div className="cs_height_150 cs_height_lg_80" />
    </>
  );
}
