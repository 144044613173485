import React from 'react';
import Spacing from '../Spacing';
import SectionHeadingStyle3 from '../SectionHeading/SectionHeadingStyle3';
import CtaStyle2 from '../Cta/CtaStyle2';
import AboutStyle6 from '../About/AboutStyle6';
import CardStyle3 from '../Card/CardStyle3';
import { pageTitle } from '../../helpers/PageTitle';

export default function ServiceDetailsPageCRO() {
  pageTitle('Conversion Rate Optimization');
  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />

      {/* Hero Section */}
      <SectionHeadingStyle3
        title="Maximize your website's performance and convert more visitors"
        subTitle="Conversion Rate Optimization"
        shape="shape_3"
      />
      <Spacing lg="75" md="60" />

      {/* About Section */}
      <div className="cs_service_info">
        <div className="container">
          <div className="row align-items-center cs_gap_y_40">
            <div className="col-lg-6">
              <div
                className="cs_service_info_thumb cs_bg_filed"
                style={{
                  backgroundImage:
                    'url("/images/others/cro.jpg")',
                }}
              />
            </div>
            <div className="col-lg-6">
              <div className="row cs_gap_y_40">
                <div className="col-sm-6">
                  <CardStyle3
                    number="01"
                    title="Landing Page Optimization"
                    subTitle="Improving landing page design to increase conversions."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="02"
                    title="A/B Testing"
                    subTitle="Testing different versions of your pages to find the best performers."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="03"
                    title="User Behavior Analysis"
                    subTitle="Analyzing user behavior to identify areas for improvement."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="04"
                    title="Conversion Funnel Analysis"
                    subTitle="Examining each stage of your conversion funnel to optimize performance."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="05"
                    title="Form Optimization"
                    subTitle="Simplifying and enhancing forms to boost conversion rates."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="06"
                    title="Heatmaps and Click Maps"
                    subTitle="Using visual tools to see where users interact most on your site."
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Spacing lg="150" md="80" />
      <AboutStyle6
        thumbnailSrc="/images/others/cro2.jpg"
        title="What you will get from this service?"
        subTitle="Our conversion rate optimization services focus on maximizing your website's performance and converting more visitors into customers. From landing page optimization to user behavior analysis, we provide comprehensive strategies to improve your conversion rates."
        featureList={[
          'Effective landing page optimization',
          'Comprehensive A/B testing',
          'In-depth user behavior analysis',
          'Detailed conversion funnel analysis',
        ]}
        btnText2="Explore Analytics and Reporting"
        btnUrl2="/service/analytics"
        btnText3="Explore On Page Optimization"
        btnUrl3="/service/optimization"
        btnText="More"
        btnUrl="/service"
      />
      <Spacing lg="50" md="80" />

      {/* CTA Section */}
      <div className="cs_height_140 cs_height_lg_70" />
      <CtaStyle2
        title="Is there a specific project or goal <br />that you have in mind?"
        btnText="Send Message"
        btnUrl="/contact"
      />
      <div className="cs_height_150 cs_height_lg_80" />
    </>
  );
}
