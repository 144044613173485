import React from 'react';
import Spacing from '../Spacing';
import SectionHeadingStyle3 from '../SectionHeading/SectionHeadingStyle3';
import CtaStyle2 from '../Cta/CtaStyle2';
import AboutStyle6 from '../About/AboutStyle6';
import CardStyle3 from '../Card/CardStyle3';
import { pageTitle } from '../../helpers/PageTitle';

export default function ServiceDetailsPageAPI() {
  pageTitle('API Integration');
  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />

      {/* Hero Section */}
      <SectionHeadingStyle3
        title="Streamline your business processes with seamless API integration"
        subTitle="API Integration"
        shape="shape_3"
      />
      <Spacing lg="75" md="60" />

      {/* About Section */}
      <div className="cs_service_info">
        <div className="container">
          <div className="row align-items-center cs_gap_y_40">
            <div className="col-lg-6">
              <div
                className="cs_service_info_thumb cs_bg_filed"
                style={{
                  backgroundImage:
                    'url("/images/others/api.jpg")',
                }}
              />
            </div>
            <div className="col-lg-6">
              <div className="row cs_gap_y_40">
                <div className="col-sm-6">
                  <CardStyle3
                    number="01"
                    title="Third-Party Integration"
                    subTitle="Seamlessly integrate third-party services to enhance your business functionality."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="02"
                    title="Custom API Development"
                    subTitle="Develop custom APIs tailored to your specific business needs."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="03"
                    title="API Testing"
                    subTitle="Ensure the reliability and performance of your APIs with comprehensive testing."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="04"
                    title="API Documentation"
                    subTitle="Provide clear and detailed documentation for easy API implementation and use."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="05"
                    title="API Security"
                    subTitle="Implement robust security measures to protect your data and API endpoints."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="06"
                    title="API Management"
                    subTitle="Efficiently manage and monitor your APIs to ensure optimal performance."
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Spacing lg="150" md="80" />
      <AboutStyle6
        thumbnailSrc="/images/others/api2.webp"
        title="What you will get from this service?"
        subTitle="Our API integration services streamline your business processes by connecting disparate systems and enhancing functionality. From custom API development to security and management, we ensure seamless and efficient integration."
        featureList={[
          'Seamless third-party integration',
          'Custom API development',
          'Comprehensive API testing',
          'Detailed API documentation',
        ]}
        btnText2="Explore Web Development"
        btnUrl2="/service/web"
        btnText3="Explore Mobile App Development"
        btnUrl3="/service/app"
        btnText="More"
        btnUrl="/service"
      />
      <Spacing lg="50" md="80" />

      {/* CTA Section */}
      <div className="cs_height_140 cs_height_lg_70" />
      <CtaStyle2
        title="Is there a specific project or goal <br />that you have in mind?"
        btnText="Send Message"
        btnUrl="/contact"
      />
      <div className="cs_height_150 cs_height_lg_80" />
    </>
  );
}
