import React from 'react';
import Spacing from '../Spacing';
import SectionHeadingStyle3 from '../SectionHeading/SectionHeadingStyle3';
import CtaStyle2 from '../Cta/CtaStyle2';
import AboutStyle6 from '../About/AboutStyle6';
import CardStyle3 from '../Card/CardStyle3';
import { pageTitle } from '../../helpers/PageTitle';

export default function ServiceDetailsPagePPC() {
  pageTitle('Pay-Per-Click Advertising');
  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />

      {/* Hero Section */}
      <SectionHeadingStyle3
        title="Drive targeted traffic to your website with strategic PPC campaigns"
        subTitle="Pay-Per-Click Advertising"
        shape="shape_3"
      />
      <Spacing lg="75" md="60" />

      {/* About Section */}
      <div className="cs_service_info">
        <div className="container">
          <div className="row align-items-center cs_gap_y_40">
            <div className="col-lg-6">
              <div
                className="cs_service_info_thumb cs_bg_filed"
                style={{
                  backgroundImage:
                    'url("/images/others/ppc.jpeg")',
                }}
              />
            </div>
            <div className="col-lg-6">
              <div className="row cs_gap_y_40">
                <div className="col-sm-6">
                  <CardStyle3
                    number="01"
                    title="Keyword Research"
                    subTitle="Identifying the most effective keywords to target your audience."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="02"
                    title="Ad Creation"
                    subTitle="Designing compelling ads that attract clicks and conversions."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="03"
                    title="Campaign Management"
                    subTitle="Managing your PPC campaigns to maximize ROI and minimize costs."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="04"
                    title="Landing Page Optimization"
                    subTitle="Creating optimized landing pages to improve conversion rates."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="05"
                    title="Performance Tracking"
                    subTitle="Monitoring and analyzing campaign performance to drive improvements."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="06"
                    title="A/B Testing"
                    subTitle="Testing different ad variations to find the most effective strategy."
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Spacing lg="150" md="80" />
      <AboutStyle6
        thumbnailSrc="/images/others/ppc2.jpg"
        title="What you will get from this service?"
        subTitle="Our PPC advertising services focus on driving targeted traffic to your website through strategic campaigns. We manage everything from keyword research to performance tracking to ensure you achieve the best results."
        featureList={[
          'Effective keyword research',
          'Compelling ad creation',
          'Comprehensive campaign management',
          'Landing page optimization',
        ]}
        btnText2="Explore SEO Services"
        btnUrl2="/service/seo"
        btnText3="Explore Analytics and Reporting"
        btnUrl3="/service/analytics"
        btnText="More"
        btnUrl="/service"
      />
      <Spacing lg="50" md="80" />

      {/* CTA Section */}
      <div className="cs_height_140 cs_height_lg_70" />
      <CtaStyle2
        title="Is there a specific project or goal <br />that you have in mind?"
        btnText="Send Message"
        btnUrl="/contact"
      />
      <div className="cs_height_150 cs_height_lg_80" />
    </>
  );
}
