import React from 'react';
import Spacing from '../Spacing';
import SectionHeadingStyle3 from '../SectionHeading/SectionHeadingStyle3';
import CtaStyle2 from '../Cta/CtaStyle2';
import AboutStyle6 from '../About/AboutStyle6';
import CardStyle3 from '../Card/CardStyle3';
import { pageTitle } from '../../helpers/PageTitle';

export default function ServiceDetailsPageReputation() {
  pageTitle('Reputation Management');
  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />

      {/* Hero Section */}
      <SectionHeadingStyle3
        title="Protect and enhance your brand's online reputation"
        subTitle="Reputation Management"
        shape="shape_3"
      />
      <Spacing lg="75" md="60" />

      {/* About Section */}
      <div className="cs_service_info">
        <div className="container">
          <div className="row align-items-center cs_gap_y_40">
            <div className="col-lg-6">
              <div
                className="cs_service_info_thumb cs_bg_filed"
                style={{
                  backgroundImage:
                    'url("/images/others/reputation.jpg")',
                }}
              />
            </div>
            <div className="col-lg-6">
              <div className="row cs_gap_y_40">
                <div className="col-sm-6">
                  <CardStyle3
                    number="01"
                    title="Online Monitoring"
                    subTitle="Keeping track of your brand's online presence and reputation."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="02"
                    title="Crisis Management"
                    subTitle="Handling and mitigating any negative incidents affecting your brand."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="03"
                    title="Review Management"
                    subTitle="Managing and responding to online reviews to maintain a positive image."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="04"
                    title="Content Creation"
                    subTitle="Creating positive content to enhance your brand's reputation."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="05"
                    title="SEO for Reputation"
                    subTitle="Using SEO techniques to push positive content and suppress negative content."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="06"
                    title="Social Media Management"
                    subTitle="Managing your social media presence to build and maintain a positive reputation."
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Spacing lg="150" md="80" />
      <AboutStyle6
        thumbnailSrc="/images/others/reputation2.jpg"
        title="What you will get from this service?"
        subTitle="Our reputation management services focus on protecting and enhancing your brand's online image. From online monitoring to crisis management, we provide comprehensive strategies to ensure your brand maintains a positive reputation."
        featureList={[
          'Comprehensive online monitoring',
          'Effective crisis management',
          'Proactive review management',
          'Strategic content creation',
        ]}
        btnText2="Explore Branding Design"
        btnUrl2="/service/branding"
        btnText3="Explore Social Media Marketing"
        btnUrl3="/service/social"
        btnText="More"
        btnUrl="/service"
      />
      <Spacing lg="50" md="80" />

      {/* CTA Section */}
      <div className="cs_height_140 cs_height_lg_70" />
      <CtaStyle2
        title="Is there a specific project or goal <br />that you have in mind?"
        btnText="Send Message"
        btnUrl="/contact"
      />
      <div className="cs_height_150 cs_height_lg_80" />
    </>
  );
}
