import React from 'react';
import Spacing from '../Spacing';
import SectionHeadingStyle3 from '../SectionHeading/SectionHeadingStyle3';
import CtaStyle2 from '../Cta/CtaStyle2';
import AboutStyle6 from '../About/AboutStyle6';
import CardStyle3 from '../Card/CardStyle3';
import { pageTitle } from '../../helpers/PageTitle';

export default function ServiceDetailsPageEmail() {
  pageTitle('Email Marketing');
  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />

      {/* Hero Section */}
      <SectionHeadingStyle3
        title="Crafting effective email campaigns to engage cold or warm leads."
        subTitle="Email Marketing"
        shape="shape_3"
      />
      <Spacing lg="75" md="60" />

      {/* About Section */}
      <div className="cs_service_info">
        <div className="container">
          <div className="row align-items-center cs_gap_y_40">
            <div className="col-lg-6">
              <div
                className="cs_service_info_thumb cs_bg_filed"
                style={{
                  backgroundImage:
                    'url("/images/others/email.jpg")',
                }}
              />
            </div>
            <div className="col-lg-6">
              <div className="row cs_gap_y_40">
                <div className="col-sm-6">
                  <CardStyle3
                    number="01"
                    title="Cold Email Campaigns"
                    subTitle="Targeting potential customers who have not interacted with your brand."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="02"
                    title="Warm Email Campaigns"
                    subTitle="Nurturing leads who have shown interest in your offerings."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="03"
                    title="High Volume Sending"
                    subTitle="Capable of sending over 8 million emails per day using warmed-up IPs."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="04"
                    title="IP and Domain Management"
                    subTitle="Ensuring domain reputation and gradual warm-up to maintain deliverability."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="05"
                    title="Inbox Delivery Assurance"
                    subTitle="Our system ensures emails are sent to the inbox, not spam."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="06"
                    title="Bulk Email Verification"
                    subTitle="State-of-the-art system with 99.2% accuracy."
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Spacing lg="150" md="80" />
      <AboutStyle6
        thumbnailSrc="/images/others/email2.webp"
        title="What you will get from this service?"
        subTitle="Our email marketing services are designed to craft effective campaigns that engage both cold and warm leads. We leverage advanced infrastructure and best practices to ensure high deliverability and engagement."
        featureList={[
          'Targeted email campaigns for both cold and warm leads',
          'High deliverability with warmed-up IPs and domain management',
          'Inbox delivery assurance to avoid spam folders',
          'Comprehensive email verification system with 99.2% accuracy',
        ]}
        btnText2="Explore Content Creation"
        btnUrl2 ="/service/content"
        btnText3="Explore Social Media Marketing"
        btnUrl3 ="/service/social"
        btnText="More"
        btnUrl="/service"
      />
      <Spacing lg="50" md="80" />

      {/* CTA Section */}
      <div className="cs_height_140 cs_height_lg_70" />
      <CtaStyle2
        title="Is there a specific project or goal <br />that you have in mind?"
        btnText="Send Message"
        btnUrl="/contact"
      />
      <div className="cs_height_150 cs_height_lg_80" />
    </>
  );
}
