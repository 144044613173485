import React from 'react';
import Spacing from '../Spacing';
import SectionHeading from '../SectionHeading';
import { pageTitle } from '../../helpers/PageTitle';

export default function PrivacyPolicy() {
    pageTitle('Privacy Policy');
    return (
        <>
            <section>
                <Spacing lg="140" md="80" />
                <div className="container">
                    <SectionHeading title="" subTitle="Privacy Policy" />
                    <Spacing lg="55" md="30" />
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1">
                            <h2>Opeyn by Sailak Solutions Private Limited</h2>
                            <p>Last Updated: 25-06-2024</p>
                            <h3>Introduction</h3>
                            <p>Welcome to Opeyn, a digital marketing website operated by Sailak Solutions Private Limited ("we," "us," "our"). Your privacy is important to us, and we are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you visit our website www.opeyn.com (the "Site").</p>
                            <h3>1. Information We Collect</h3>
                            <p>We may collect and process the following types of information:</p>
                            <h4>Personal Identification Information:</h4>
                            <ul>
                                <li>Name</li>
                                <li>Email address</li>
                                <li>Phone number</li>
                                <li>Company name</li>
                                <li>Job title</li>
                            </ul>
                            <h4>Non-Personal Identification Information:</h4>
                            <ul>
                                <li>Browser type</li>
                                <li>Operating system</li>
                                <li>IP address</li>
                                <li>Referring website</li>
                                <li>Pages visited on our Site</li>
                                <li>Time and date of visits</li>
                            </ul>
                            <h3>2. How We Collect Information</h3>
                            <p>We collect information in the following ways:</p>
                            <h4>Directly from You:</h4>
                            <ul>
                                <li>When you fill out forms on our Site</li>
                                <li>When you subscribe to our newsletter</li>
                                <li>When you contact us for support or inquiries</li>
                            </ul>
                            <h4>Automatically:</h4>
                            <ul>
                                <li>Through cookies and similar tracking technologies</li>
                                <li>Through server logs</li>
                            </ul>
                            <h3>3. How We Use Your Information</h3>
                            <p>We may use the information we collect for various purposes, including:</p>
                            <ul>
                                <li>To provide, operate, and maintain our Site</li>
                                <li>To improve, personalize, and expand our Site</li>
                                <li>To understand and analyze how you use our Site</li>
                                <li>To develop new products, services, features,</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <Spacing lg="120" md="50" />
            </section>
        </>
    );
}
