import React from 'react';
import Spacing from '../Spacing';
import SectionHeadingStyle3 from '../SectionHeading/SectionHeadingStyle3';
import CtaStyle2 from '../Cta/CtaStyle2';
import AboutStyle6 from '../About/AboutStyle6';
import CardStyle3 from '../Card/CardStyle3';
import { pageTitle } from '../../helpers/PageTitle';

export default function ServiceDetailsPageBranding() {
  pageTitle('Branding Design');
  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />

      {/* Hero Section */}
      <SectionHeadingStyle3
        title="Develop a strong brand identity that resonates with your audience"
        subTitle="Branding Design"
        shape="shape_3"
      />
      <Spacing lg="75" md="60" />

      {/* About Section */}
      <div className="cs_service_info">
        <div className="container">
          <div className="row align-items-center cs_gap_y_40">
            <div className="col-lg-6">
              <div
                className="cs_service_info_thumb cs_bg_filed"
                style={{
                  backgroundImage:
                    'url("/images/others/branding.jpg")',
                }}
              />
            </div>
            <div className="col-lg-6">
              <div className="row cs_gap_y_40">
                <div className="col-sm-6">
                  <CardStyle3
                    number="01"
                    title="Logo Design"
                    subTitle="Crafting unique and memorable logos that represent your brand identity."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="02"
                    title="Brand Guidelines"
                    subTitle="Creating comprehensive brand guidelines to ensure consistency across all platforms."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="03"
                    title="Business Cards"
                    subTitle="Designing professional business cards that leave a lasting impression."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="04"
                    title="Marketing Materials"
                    subTitle="Developing cohesive marketing materials to promote your brand."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="05"
                    title="Packaging Design"
                    subTitle="Creating attractive packaging designs that reflect your brand's essence."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="06"
                    title="Website Design"
                    subTitle="Designing websites that embody your brand's visual identity."
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Spacing lg="150" md="80" />
      <AboutStyle6
        thumbnailSrc="/images/others/branding2.jpg"
        title="What you will get from this service?"
        subTitle="Our branding design services focus on creating a strong and cohesive brand identity that resonates with your target audience. From logo design to packaging and website design, we ensure your brand stands out."
        featureList={[
          'Unique logo design',
          'Comprehensive brand guidelines',
          'Professional marketing materials',
          'Consistent and attractive visual identity',
        ]}
        btnText2="Explore Content Creation"
        btnUrl2="/service/content"
        btnText3="Explore Reputation Management"
        btnUrl3="/service/reputation"
        btnText="More"
        btnUrl="/service"
      />
      <Spacing lg="50" md="80" />

      {/* CTA Section */}
      <div className="cs_height_140 cs_height_lg_70" />
      <CtaStyle2
        title="Is there a specific project or goal <br />that you have in mind?"
        btnText="Send Message"
        btnUrl="/contact"
      />
      <div className="cs_height_150 cs_height_lg_80" />
    </>
  );
}
