// components/Metadata.js
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

const Metadata = () => {
    const location = useLocation();

    // Define metadata and structured data for each route
    const getPageMetadata = (pathname) => {
        switch (pathname) {
            case '/':
                return {
                    title: 'Home - Opeyn',
                    description: 'We provide cutting-edge digital marketing and tech solutions tailored to your needs. Leverage our advanced lead scoring techniques and comprehensive sales intelligence. Explore our custom web and app development services, SOPs, and templates designed to streamline your processes. Elevate your online presence with targeted ads, expert SEO strategies, and innovative digital tools.',
                    keywords: 'home, opeyn, welcome, open source, marketing, AI in marketing, digital marketing, tech solutions, web and app development, services, SOPs, templates, setup marketing, branding, IT solutions, automations, consultancy',
                    structuredData: {
                        "@context": "https://schema.org",
                        "@type": "WebSite",
                        "name": "Opeyn",
                        "url": "https://www.opeyn.com",
                        "potentialAction": {
                            "@type": "SearchAction",
                            "target": "https://www.opeyn.com/search?q={search_term_string}",
                            "query-input": "required name=search_term_string"
                        }
                    }
                };
            case '/about':
                return {
                    title: 'About Us - Opeyn',
                    description: 'Adding value to your business, making it worthy. Learn more about Opeyn.',
                    keywords: 'about, opeyn, company',
                    structuredData: {
                        "@context": "https://schema.org",
                        "@type": "Organization",
                        "name": "Opeyn",
                        "url": "https://www.opeyn.com",
                        "logo": "https://www.opeyn.com/favicon512.png",
                        "sameAs": [
                            "https://www.linkedin.com/company/opeyn",
                            "https://www.instagram.com/opeyn_marketing"
                        ]
                    }
                };
            case '/digital-marketing':
                return {
                    title: 'Digital Marketing - Opeyn',
                    description: 'Explore our digital marketing services.',
                    keywords: 'digital marketing, opeyn, services',
                    structuredData: {
                        "@context": "https://schema.org",
                        "@type": "Service",
                        "serviceType": "Digital Marketing",
                        "provider": {
                            "@type": "Organization",
                            "name": "Opeyn",
                            "url": "https://www.opeyn.com"
                        }
                    }
                };
            case '/contact':
                return {
                    title: 'Contact Us - Opeyn',
                    description: 'Get in touch with Opeyn.',
                    keywords: 'contact, opeyn, support',
                    structuredData: {
                        "@context": "https://schema.org",
                        "@type": "ContactPage",
                        "url": "https://www.opeyn.com/contact",
                        "contactType": "Customer Support",
                        "description": "Contact Opeyn for customer support."
                    }
                };
            case '/tech-solutions':
                return {
                    title: 'Tech Solutions - Opeyn',
                    description: 'Explore our technical solutions tailored to your needs.',
                    keywords: 'tech solutions, opeyn, technology',
                    structuredData: {
                        "@context": "https://schema.org",
                        "@type": "Service",
                        "serviceType": "Tech Solutions",
                        "provider": {
                            "@type": "Organization",
                            "name": "Opeyn",
                            "url": "https://www.opeyn.com"
                        }
                    }
                };
            case '/service/content':
                return {
                    title: 'Content Creation - Opeyn',
                    description: 'Create and distribute valuable content to attract and engage your target audience effectively.',
                    keywords: 'content creation, opeyn, content marketing',
                    structuredData: {
                        "@context": "https://schema.org",
                        "@type": "Service",
                        "serviceType": "Content Creation",
                        "provider": {
                            "@type": "Organization",
                            "name": "Opeyn",
                            "url": "https://www.opeyn.com"
                        }
                    }
                };
            case '/service/email':
                return {
                    title: 'Email Marketing - Opeyn',
                    description: 'Design and execute email campaigns to convert leads into loyal customers.',
                    keywords: 'email marketing, opeyn, email campaigns',
                    structuredData: {
                        "@context": "https://schema.org",
                        "@type": "Service",
                        "serviceType": "Email Marketing",
                        "provider": {
                            "@type": "Organization",
                            "name": "Opeyn",
                            "url": "https://www.opeyn.com"
                        }
                    }
                };
            case '/service/web':
                return {
                    title: 'Web Development - Opeyn',
                    description: 'Create tailored, responsive websites that enhance user experience and optimize performance.',
                    keywords: 'web development, opeyn, responsive websites',
                    structuredData: {
                        "@context": "https://schema.org",
                        "@type": "Service",
                        "serviceType": "Web Development",
                        "provider": {
                            "@type": "Organization",
                            "name": "Opeyn",
                            "url": "https://www.opeyn.com"
                        }
                    }
                };
            case '/service/app':
                return {
                    title: 'Mobile App Development - Opeyn',
                    description: 'Develop seamless, high-quality mobile applications for all devices, providing exceptional user experiences.',
                    keywords: 'mobile app development, opeyn, mobile applications',
                    structuredData: {
                        "@context": "https://schema.org",
                        "@type": "Service",
                        "serviceType": "Mobile App Development",
                        "provider": {
                            "@type": "Organization",
                            "name": "Opeyn",
                            "url": "https://www.opeyn.com"
                        }
                    }
                };
            case '/service/branding':
                return {
                    title: 'Branding Design - Opeyn',
                    description: 'Develop a strong brand identity with our comprehensive branding and design services.',
                    keywords: 'branding design, opeyn, brand identity',
                    structuredData: {
                        "@context": "https://schema.org",
                        "@type": "Service",
                        "serviceType": "Branding Design",
                        "provider": {
                            "@type": "Organization",
                            "name": "Opeyn",
                            "url": "https://www.opeyn.com"
                        }
                    }
                };
            case '/service/optimization':
                return {
                    title: 'On Page Optimization - Opeyn',
                    description: 'Optimize your website\'s content and structure to improve visibility and search engine ranking.',
                    keywords: 'on page optimization, opeyn, SEO',
                    structuredData: {
                        "@context": "https://schema.org",
                        "@type": "Service",
                        "serviceType": "On Page Optimization",
                        "provider": {
                            "@type": "Organization",
                            "name": "Opeyn",
                            "url": "https://www.opeyn.com"
                        }
                    }
                };
            case '/service/social':
                return {
                    title: 'Social Media Marketing - Opeyn',
                    description: 'Boost your online presence and engagement with targeted social media strategies and campaigns.',
                    keywords: 'social media marketing, opeyn, social media',
                    structuredData: {
                        "@context": "https://schema.org",
                        "@type": "Service",
                        "serviceType": "Social Media Marketing",
                        "provider": {
                            "@type": "Organization",
                            "name": "Opeyn",
                            "url": "https://www.opeyn.com"
                        }
                    }
                };
            case '/service/seo':
                return {
                    title: 'SEO Services - Opeyn',
                    description: 'Enhance your website\'s search engine rankings with our expert SEO strategies and techniques.',
                    keywords: 'SEO services, opeyn, search engine optimization',
                    structuredData: {
                        "@context": "https://schema.org",
                        "@type": "Service",
                        "serviceType": "SEO Services",
                        "provider": {
                            "@type": "Organization",
                            "name": "Opeyn",
                            "url": "https://www.opeyn.com"
                        }
                    }
                };
            case '/service/ppc':
                return {
                    title: 'Pay-Per-Click Advertising - Opeyn',
                    description: 'Drive targeted traffic to your website with strategic PPC campaigns and maximize your ROI.',
                    keywords: 'PPC advertising, opeyn, pay-per-click',
                    structuredData: {
                        "@context": "https://schema.org",
                        "@type": "Service",
                        "serviceType": "Pay-Per-Click Advertising",
                        "provider": {
                            "@type": "Organization",
                            "name": "Opeyn",
                            "url": "https://www.opeyn.com"
                        }
                    }
                };
            case '/service/influencer':
                return {
                    title: 'Influencer Marketing - Opeyn',
                    description: 'Leverage influencers to promote your brand and reach a wider audience.',
                    keywords: 'influencer marketing, opeyn, influencers',
                    structuredData: {
                        "@context": "https://schema.org",
                        "@type": "Service",
                        "serviceType": "Influencer Marketing",
                        "provider": {
                            "@type": "Organization",
                            "name": "Opeyn",
                            "url": "https://www.opeyn.com"
                        }
                    }
                };
            case '/service/analytics':
                return {
                    title: 'Analytics and Reporting - Opeyn',
                    description: 'Track and measure your marketing efforts with detailed analytics and reporting.',
                    keywords: 'analytics, opeyn, reporting',
                    structuredData: {
                        "@context": "https://schema.org",
                        "@type": "Service",
                        "serviceType": "Analytics and Reporting",
                        "provider": {
                            "@type": "Organization",
                            "name": "Opeyn",
                            "url": "https://www.opeyn.com"
                        }
                    }
                };
            case '/service/cro':
                return {
                    title: 'Conversion Rate Optimization - Opeyn',
                    description: 'Improve your website\'s conversion rates with targeted optimization strategies.',
                    keywords: 'conversion rate optimization, opeyn, CRO',
                    structuredData: {
                        "@context": "https://schema.org",
                        "@type": "Service",
                        "serviceType": "Conversion Rate Optimization",
                        "provider": {
                            "@type": "Organization",
                            "name": "Opeyn",
                            "url": "https://www.opeyn.com"
                        }
                    }
                };
            case '/service/reputation':
                return {
                    title: 'Reputation Management - Opeyn',
                    description: 'Manage and enhance your online reputation with our proactive reputation management services.',
                    keywords: 'reputation management, opeyn, online reputation',
                    structuredData: {
                        "@context": "https://schema.org",
                        "@type": "Service",
                        "serviceType": "Reputation Management",
                        "provider": {
                            "@type": "Organization",
                            "name": "Opeyn",
                            "url": "https://www.opeyn.com"
                        }
                    }
                };
            case '/service/api':
                return {
                    title: 'API Integration - Opeyn',
                    description: 'Integrate third-party services and APIs to extend functionality and streamline business processes.',
                    keywords: 'API integration, opeyn, third-party services',
                    structuredData: {
                        "@context": "https://schema.org",
                        "@type": "Service",
                        "serviceType": "API Integration",
                        "provider": {
                            "@type": "Organization",
                            "name": "Opeyn",
                            "url": "https://www.opeyn.com"
                        }
                    }
                };
            case '/service/uiux':
                return {
                    title: 'UI/UX Design - Opeyn',
                    description: 'Design intuitive and visually appealing user interfaces to enhance user engagement and satisfaction.',
                    keywords: 'UI/UX design, opeyn, user interfaces',
                    structuredData: {
                        "@context": "https://schema.org",
                        "@type": "Service",
                        "serviceType": "UI/UX Design",
                        "provider": {
                            "@type": "Organization",
                            "name": "Opeyn",
                            "url": "https://www.opeyn.com"
                        }
                    }
                };
            // case '/pricing':
            //     return {
            //         title: 'Pricing - Opeyn',
            //         description: 'Explore our pricing plans and choose the one that best fits your needs.',
            //         keywords: 'pricing, plans, opeyn',
            //         structuredData: {
            //             "@context": "https://schema.org",
            //             "@type": "WebPage",
            //             "name": "Pricing",
            //             "url": "https://www.opeyn.com/pricing"
            //         }
            //     };
            case '/pricing/pricing-web':
                return {
                    title: 'Pricing for Web Services - Opeyn',
                    description: 'Explore our pricing plans for web services and choose the one that best fits your needs.',
                    keywords: 'pricing, plans, web services, opeyn',
                    structuredData: {
                        "@context": "https://schema.org",
                        "@type": "WebPage",
                        "name": "Pricing for Web Services",
                        "url": "https://www.opeyn.com/pricing/pricing-web"
                    }
                };
            case '/pricing/pricing-app':
                return {
                    title: 'Pricing for App Services - Opeyn',
                    description: 'Explore our pricing plans for app services and choose the one that best fits your needs.',
                    keywords: 'pricing, plans, app services, opeyn',
                    structuredData: {
                        "@context": "https://schema.org",
                        "@type": "WebPage",
                        "name": "Pricing for App Services",
                        "url": "https://www.opeyn.com/pricing/pricing-app"
                    }
                };
            case '/t&c':
                return {
                    title: 'Terms and Conditions - Opeyn',
                    description: 'Read our terms and conditions to understand the rules and guidelines of using our services.',
                    keywords: 'terms and conditions, t&c, opeyn',
                    structuredData: {
                        "@context": "https://schema.org",
                        "@type": "WebPage",
                        "name": "Terms and Conditions",
                        "url": "https://www.opeyn.com/t&c"
                    }
                };
            case '/works':
                return {
                    title: 'Our Works - Opeyn',
                    description: 'Explore the projects we have completed and see the results of our expertise.',
                    keywords: 'our works, portfolio, opeyn',
                    structuredData: {
                        "@context": "https://schema.org",
                        "@type": "CreativeWork",
                        "name": "Our Works",
                        "url": "https://www.opeyn.com/works",
                        "exampleOfWork": [
                            {
                                "@type": "Zuesly",
                                "url": "https://zuesly.vercel.app/",
                                "name": "Example Project 1",
                                "description": "Simplify your SaaS solution with AI - Our AI SAAS tool is a cloud-based software delivery model. It helps businesses forecast demand for products and services and optimize inventory management and supply chain operations."
                            },
                            {
                                "@type": "Plans-Techy",
                                "url": "https://plans-techy.vercel.app/",
                                "name": "Example Project 2",
                                "description": "We’re an innovative IT Solutions - YOUR NEXT IT SOLUTION AGENCY & EXPLORE MORE"
                            }
                        ]
                    }
                };
            case '/service':
                return {
                    title: 'Services - Opeyn',
                    description: 'Discover the comprehensive services we offer to help your business thrive in the digital world.',
                    keywords: 'services, opeyn, digital solutions',
                    structuredData: {
                        "@context": "https://schema.org",
                        "@type": "ItemList",
                        "name": "Services",
                        "itemListElement": [
                            {
                                "@type": "Service",
                                "serviceType": "Web Development",
                                "provider": {
                                    "@type": "Organization",
                                    "name": "Opeyn",
                                    "url": "https://www.opeyn.com"
                                }
                            },
                            {
                                "@type": "Service",
                                "serviceType": "Mobile App Development",
                                "provider": {
                                    "@type": "Organization",
                                    "name": "Opeyn",
                                    "url": "https://www.opeyn.com"
                                }
                            },
                            {
                                "@type": "Service",
                                "serviceType": "Branding Design",
                                "provider": {
                                    "@type": "Organization",
                                    "name": "Opeyn",
                                    "url": "https://www.opeyn.com"
                                }
                            },
                            {
                                "@type": "Service",
                                "serviceType": "On Page Optimization",
                                "provider": {
                                    "@type": "Organization",
                                    "name": "Opeyn",
                                    "url": "https://www.opeyn.com"
                                }
                            },
                            {
                                "@type": "Service",
                                "serviceType": "Social Media Marketing",
                                "provider": {
                                    "@type": "Organization",
                                    "name": "Opeyn",
                                    "url": "https://www.opeyn.com"
                                }
                            },
                            {
                                "@type": "Service",
                                "serviceType": "Content Marketing",
                                "provider": {
                                    "@type": "Organization",
                                    "name": "Opeyn",
                                    "url": "https://www.opeyn.com"
                                }
                            },
                            {
                                "@type": "Service",
                                "serviceType": "SEO Services",
                                "provider": {
                                    "@type": "Organization",
                                    "name": "Opeyn",
                                    "url": "https://www.opeyn.com"
                                }
                            },
                            {
                                "@type": "Service",
                                "serviceType": "Pay-Per-Click Advertising",
                                "provider": {
                                    "@type": "Organization",
                                    "name": "Opeyn",
                                    "url": "https://www.opeyn.com"
                                }
                            },
                            {
                                "@type": "Service",
                                "serviceType": "Email Marketing",
                                "provider": {
                                    "@type": "Organization",
                                    "name": "Opeyn",
                                    "url": "https://www.opeyn.com"
                                }
                            },
                            {
                                "@type": "Service",
                                "serviceType": "Influencer Marketing",
                                "provider": {
                                    "@type": "Organization",
                                    "name": "Opeyn",
                                    "url": "https://www.opeyn.com"
                                }
                            },
                            {
                                "@type": "Service",
                                "serviceType": "Analytics and Reporting",
                                "provider": {
                                    "@type": "Organization",
                                    "name": "Opeyn",
                                    "url": "https://www.opeyn.com"
                                }
                            },
                            {
                                "@type": "Service",
                                "serviceType": "Conversion Rate Optimization",
                                "provider": {
                                    "@type": "Organization",
                                    "name": "Opeyn",
                                    "url": "https://www.opeyn.com"
                                }
                            },
                            {
                                "@type": "Service",
                                "serviceType": "Reputation Management",
                                "provider": {
                                    "@type": "Organization",
                                    "name": "Opeyn",
                                    "url": "https://www.opeyn.com"
                                }
                            },
                            {
                                "@type": "Service",
                                "serviceType": "API Integration",
                                "provider": {
                                    "@type": "Organization",
                                    "name": "Opeyn",
                                    "url": "https://www.opeyn.com"
                                }
                            },
                            {
                                "@type": "Service",
                                "serviceType": "UI/UX Design",
                                "provider": {
                                    "@type": "Organization",
                                    "name": "Opeyn",
                                    "url": "https://www.opeyn.com"
                                }
                            }
                        ]
                    }
                };
            default:
                return {
                    title: 'Opeyn',
                    description: 'Opeyn - Your one-stop solution.',
                    keywords: 'opeyn, solution',
                    structuredData: {
                        "@context": "https://schema.org",
                        "@type": "WebSite",
                        "name": "Opeyn",
                        "url": "https://www.opeyn.com"
                    }
                };
        }
    };
    



    const metadata = getPageMetadata(location.pathname);

    useEffect(() => {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.async = true;
        script.innerHTML = `
            window.faitracker = window.faitracker || function() {
                this.q = [];
                var t = new CustomEvent("FAITRACKER_QUEUED_EVENT");
                return this.init = function(t, e, a) {
                    this.TOKEN = t, this.INIT_PARAMS = e, this.INIT_CALLBACK = a, window.dispatchEvent(new CustomEvent("FAITRACKER_INIT_EVENT"))
                },
                this.call = function() {
                    var e = { k: "", a: [] };
                    if (arguments && arguments.length >= 1) {
                        for (var a = 1; a < arguments.length; a++) e.a.push(arguments[a]);
                        e.k = arguments[0]
                    }
                    this.q.push(e), window.dispatchEvent(t)
                },
                this.message = function() {
                    window.addEventListener("message", function(t) {
                        "faitracker" === t.data.origin && this.call("message", t.data.type, t.data.message)
                    })
                },
                this.message(), this.init("hk8g4un96vwujnhqftsrbzsuov7td7ji", { host: "https://api.factors.ai" }), this
            }(),
            function() {
                var t = document.createElement("script");
                t.type = "text/javascript", t.src = "https://app.factors.ai/assets/factors.js", t.async = !0, (d = document.getElementsByTagName("script")[0]).parentNode.insertBefore(t, d)
            }();
        `;
        document.body.appendChild(script);
    }, []);

    return (
        <Helmet>
            <title>{metadata.title}</title>
            <meta name="description" content={metadata.description} />
            <meta name="keywords" content={metadata.keywords} />
            <meta property="og:title" content={metadata.title} />
            <meta property="og:description" content={metadata.description} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={`https://www.opeyn.com${location.pathname}`} />
            <meta property="og:image" content="https://www.opeyn.com/favicon.png" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={metadata.title} />
            <meta name="twitter:description" content={metadata.description} />
            <meta name="twitter:image" content="https://www.opeyn.com/favicon.png" />
            <script type="application/ld+json">
                {JSON.stringify(metadata.structuredData)}
            </script>
        </Helmet>
    );
};

export default Metadata;
