import React from 'react';
import Spacing from '../Spacing';
import HeroStyle2 from '../Hero/HeroStyle2';
// import Brands from '../Brands';
import Marquee from '../Marquee';
import Cta from '../Cta';
import SectionHeading from '../SectionHeading';
import Accordion from '../Accordion';
// import PostCarousel from '../Slider/PostCarousel';
// import TestimonialSlider from '../Slider/TestimonialSlider';
// import Award from '../Award';
// import VideoModal from '../VideoModal';
import ServiceSlider from '../Slider/ServiceSlider';
import AboutStyle2 from '../About/AboutStyle2';
// import FunFact from '../FunFact';
// import Button from '../Button';
// import CaseStudy from '../CaseStudy';
import { pageTitle } from '../../helpers/PageTitle';

const servideData = [
  {
    iconSrc: '/images/digital-marketing/marketing.png',
    title: 'Social Media Marketing',
    subTitle:
      "Boost your online presence and engagement with targeted social media campaigns.",
    btnText: 'See More',
    btnUrl: '/service/social',
  },
  {
    iconSrc: '/images/digital-marketing/content-writing.png',
    title: 'Content Marketing',
    subTitle:
      "Create and distribute valuable content to effectively engage your target audience.",
    btnText: 'See More',
    btnUrl: '/service/content',
  },
  {
    iconSrc: '/images/digital-marketing/email-marketing.png',
    title: 'Email Marketing',
    subTitle:
      "Design and execute email campaigns to convert leads into loyal customers.",
    btnText: 'See More',
    btnUrl: '/service/email',
  },
  {
    iconSrc: '/images/digital-marketing/pay-per-click.png',
    title: 'Pay-Per-Click Advertising',
    subTitle:
      "Drive targeted traffic to your website with strategic PPC campaigns.",
    btnText: 'See More',
    btnUrl: '/service/ppc',
  },
  {
    iconSrc: '/images/digital-marketing/seo.png',
    title: 'SEO Services',
    subTitle:
      "Enhance your website's search engine rankings with our expert SEO strategies.",
    btnText: 'See More',
    btnUrl: '/service/seo',
  },
  {
    iconSrc: '/images/digital-marketing/smartphone.png',
    title: 'Influencer Marketing',
    subTitle:
      "Leverage influencers to promote your brand and reach a wider audience.",
    btnText: 'See More',
    btnUrl: '/service/influencer',
  },
  {
    iconSrc: '/images/digital-marketing/service_icon_1.svg',
    title: 'On Page Optimization',
    subTitle:
      "Optimize your website's content and structure to improve visibility and search engine ranking.",
    btnText: 'See More',
    btnUrl: '/service/optimization',
  },
  {
    iconSrc: '/images/digital-marketing/service_icon_2.svg',
    title: 'Marketing Consulting',
    subTitle:
      "Receive expert advice to craft effective marketing strategies tailored to your business.",
    btnText: 'See More',
    btnUrl: '/service/seo',
  },
  {
    iconSrc: '/images/digital-marketing/service_icon_3.svg',
    title: 'Video Campaign',
    subTitle:
      "Create compelling video content that engages your audience and boosts your brand.",
    btnText: 'See More',
    btnUrl: '/service/social',
  },
  {
    iconSrc: '/images/digital-marketing/service_icon_4.svg',
    title: 'Branding Design',
    subTitle:
      "Develop a strong brand identity with our comprehensive branding and design services.",
    btnText: 'See More',
    btnUrl: '/service/branding',
  },
  {
    iconSrc: '/images/digital-marketing/analysis.png',
    title: 'Analytics and Reporting',
    subTitle:
      "Track and measure your marketing efforts with detailed analytics and reporting.",
    btnText: 'See More',
    btnUrl: '/service/analytics',
  },
  {
    iconSrc: '/images/digital-marketing/conversion.png',
    title: 'CRO',
    subTitle:
      "Improve your website's conversion rates with targeted optimization strategies.",
    btnText: 'See More',
    btnUrl: '/service/cro',
  },
  {
    iconSrc: '/images/digital-marketing/reputation.png',
    title: 'Reputation Management',
    subTitle:
      "Manage and enhance your online reputation with our proactive reputation management.",
    btnText: 'See More',
    btnUrl: '/service/reputation',
  },
];


// const funfactData = [
//   { title: 'Happy Customers', number: '22k' },
//   { title: 'Work’s Completed', number: '15k' },
//   { title: 'Skilled Team Members', number: '121' },
//   { title: 'Most Valuable Awards', number: '15' },
// ];

// const awardData = [
//   {
//     brand: 'Behance',
//     title: 'UI/UX design of the month',
//     subTitle:
//       'Accusamus et iusto odio dignissimos ducimus qui blanditiis fedarals praesentium voluptatum deleniti atque corrupti quos dolores',
//     date: 'December 12, 2023',
//     awardImgUrl: '/images/home-page/award_img_1.svg',
//   },
//   {
//     brand: 'Awwwards',
//     title: 'CSS awards design',
//     subTitle:
//       'Accusamus et iusto odio dignissimos ducimus qui blanditiis fedarals praesentium voluptatum deleniti atque corrupti quos dolores',
//     date: 'January 05, 2022',
//     awardImgUrl: '/images/home-page/award_img_2.svg',
//   },
//   {
//     brand: 'Google',
//     title: 'Website of the day',
//     subTitle:
//       'Accusamus et iusto odio dignissimos ducimus qui blanditiis fedarals praesentium voluptatum deleniti atque corrupti quos dolores',
//     date: 'March 20, 2021',
//     awardImgUrl: '/images/home-page/award_img_3.svg',
//   },
// ];

// const testimonialData = [
//   {
//     text: 'Zivans Motion Graphics did an excellent job on my video related projects. The motion graphics added an extra layer of polish and really brought the video to life. I highly recommend their high quality services and work.',
//     avatarName: 'Ansari Patron',
//     avatarDesignation: 'CEO at Delta',
//   },
//   {
//     text: 'Zivans Motion Graphics did an excellent job on my video related projects. The motion graphics added an extra layer of polish and really brought the video to life. I highly recommend their high quality services and work.',
//     avatarName: 'Jhon Doe',
//     avatarDesignation: 'Manager at Delta',
//   },
//   {
//     text: 'Zivans Motion Graphics did an excellent job on my video related projects. The motion graphics added an extra layer of polish and really brought the video to life. I highly recommend their high quality services and work.',
//     avatarName: 'Ramatam Coo',
//     avatarDesignation: 'MD at Delta',
//   },
// ];

// const caseStudyData = [
//   {
//     thumbnailSrc: '/images/digital-marketing/case_study_1.jpeg',
//     title: 'Digital marketing management',
//     category: 'Marketing',
//     number: '01',
//     href: '/case-study-details',
//   },
//   {
//     thumbnailSrc: '/images/digital-marketing/case_study_2.jpeg',
//     title: 'Digital marketing management',
//     category: 'Marketing',
//     number: '02',
//     href: '/case-study-details',
//   },
//   {
//     thumbnailSrc: '/images/digital-marketing/case_study_3.jpeg',
//     title: 'Digital marketing management',
//     category: 'Marketing',
//     number: '03',
//     href: '/case-study-details',
//   },
//   {
//     thumbnailSrc: '/images/digital-marketing/case_study_4.jpeg',
//     title: 'Digital marketing management',
//     category: 'Marketing',
//     number: '04',
//     href: '/case-study-details',
//   },
// ];

// const postData = [
//   {
//     thumbnailSrc: '/images/home-page/post_1.jpeg',
//     title: 'How to keep fear from ruining your art business with confident',
//     date: '07 Mar 2023',
//     url: '/blog/blog-details',
//   },
//   {
//     thumbnailSrc: '/images/home-page/post_2.jpeg',
//     title: 'Artistic mind will be great for creation anything',
//     date: '22 Apr 2023',
//     url: '/blog/blog-details',
//   },
//   {
//     thumbnailSrc: '/images/home-page/post_3.jpeg',
//     title: 'AI will take over all job for human within few years',
//     date: '13 May 2023',
//     url: '/blog/blog-details',
//   },
//   {
//     thumbnailSrc: '/images/home-page/post_4.jpeg',
//     title: 'Your agency need to replace some artistic mind people',
//     date: '15 Mar 2023',
//     url: '/blog/blog-details',
//   },
//   {
//     thumbnailSrc: '/images/home-page/post_1.jpeg',
//     title: 'How to keep fear from ruining your art business with confident',
//     date: '07 Mar 2023',
//     url: '/blog/blog-details',
//   },
//   {
//     thumbnailSrc: '/images/home-page/post_2.jpeg',
//     title: 'Artistic mind will be great for creation anything',
//     date: '22 Apr 2023',
//     url: '/blog/blog-details',
//   },
//   {
//     thumbnailSrc: '/images/home-page/post_3.jpeg',
//     title: 'AI will take over all job for human within few years',
//     date: '13 May 2023',
//     url: '/blog/blog-details',
//   },
//   {
//     thumbnailSrc: '/images/home-page/post_4.jpeg',
//     title: 'Your agency need to replace some artistic mind people',
//     date: '15 Mar 2023',
//     url: '/blog/blog-details',
//   },
// ];

const faqData = [
  {
    title: '01. How can I contact you for your services?',
    content:
      'You can reach us via email at connect@opeyn.com for any inquiries or to discuss your project requirements. We look forward to helping you achieve your digital goals.',
  },
  {
    title: '02. What types of services do you provide?',
    content:
      'We offer a range of services including Custom Web Development, Mobile App Development, Branding, Social Ad Campaigns, UI/UX Design, and SEO & Digital Marketing. Our comprehensive solutions are tailored to meet your specific business needs.',
  },
  {
    title: '03. What are the different stages of the working process?',
    content:
      'Our process includes an initial consultation to understand your needs, followed by planning and strategy development, design and development, testing and quality assurance, and finally, deployment and ongoing support. We ensure each stage is carefully managed to deliver the best results.',
  },
  {
    title: '04. What is the difference between web development and mobile app development?',
    content:
      'Web development focuses on creating websites that are accessible via internet browsers, whereas mobile app development involves creating applications specifically designed for mobile devices. Both services aim to provide seamless user experiences, but they cater to different platforms and user interactions.',
  },
  {
    title: '05. How can I proceed with payment after the project is completed?',
    content:
      'Once your project is completed to your satisfaction, we will provide you with a detailed invoice. Payment can be made through various methods including bank transfer, credit card, or other electronic payment options. We ensure a smooth and secure payment process.',
  },
];

// const brandList = [
//   {
//     logoSrc: '/images/digital-marketing/brand_1.svg',
//     logoAlt: 'Brand',
//   },
//   {
//     logoSrc: '/images/digital-marketing/brand_2.svg',
//     logoAlt: 'Brand',
//   },
//   {
//     logoSrc: '/images/digital-marketing/brand_3.svg',
//     logoAlt: 'Brand',
//   },
//   {
//     logoSrc: '/images/digital-marketing/brand_4.svg',
//     logoAlt: 'Brand',
//   },
// ];

// const brandListDark = [
//   {
//     logoSrc: '/images/digital-marketing/brand_1_dark.svg',
//     logoAlt: 'Brand',
//   },
//   {
//     logoSrc: '/images/digital-marketing/brand_2_dark.svg',
//     logoAlt: 'Brand',
//   },
//   {
//     logoSrc: '/images/digital-marketing/brand_3_dark.svg',
//     logoAlt: 'Brand',
//   },
//   {
//     logoSrc: '/images/digital-marketing/brand_4_dark.svg',
//     logoAlt: 'Brand',
//   },
// ];

export default function MarketingAgencyPage({ darkMode }) {
  pageTitle('Digital Marketing');
  return (
    <>
      {/* This area below is Hero Section */}
      <HeroStyle2
        miniTitle="Digital Marketing"
        title="Unlock Your Business's Potential with Expert Digital Marketing Solutions"
        subTitle="Our agency offers a comprehensive suite of services, including Branding, Advertising, Social Marketing, Video Production, and Marketing Analysis."
        thumbnailSrc="/images/digital-marketing/hiro_img.jpeg"
        mikeIcon={
          darkMode ? '/images/icons/mike_dark.svg' : '/images/icons/mike.svg'
        }
      />

      {/* This area below is Services*/}
      <section className="cs_p76_full_width" id="service">
        <Spacing lg="143" md="75" />
        <SectionHeading title="Services we provide" subTitle="Our Services" />
        <Spacing lg="85" md="45" />
        <ServiceSlider data={servideData} />
      </section>

      {/* This area below is Who We Are */}
      <section>
        <div className="cs_height_150 cs_height_lg_75" />
        <AboutStyle2
          thumbnailSrc1="/others-image/about_img_1.png"
          thumbnailSrc2="/others-image/about_img_2.png"
          uperTitle="Who We Are"
          title="Our Priorities Equal Your Need"
          subTitle="Our team excels in providing cutting-edge digital marketing and tech solutions tailored to your needs. We leverage advanced lead scoring techniques and comprehensive sales intelligence to drive results. Explore our custom web and app development services, SOPs, and templates designed to streamline your processes. Elevate your online presence with targeted ads, expert SEO strategies, and innovative digital tools. We combine imaginative thinking, consumer behavior insights, and data-driven design with advanced technology to deliver unparalleled brand experiences."
          featureList={[
            'Optimize Your Website for Better Visibility',
            'Expert Marketing Consulting Services',
            // 'Engaging and Effective Video Campaigns',
            'Creative and Impactful Branding Design',
            'Boost Your Presence with Social Media Marketing',
            // 'Engage Your Audience with Content Marketing',
            // 'Convert Leads with Targeted Email Marketing',
            // 'Maximize ROI with PPC Advertising',
            // 'Enhance Rankings with SEO Services',
            // 'Expand Reach with Influencer Marketing',
            // 'Detailed Analytics and Reporting',
            // 'Improve Conversions with Optimization Strategies',
            // 'Manage Your Online Reputation Effectively',
          ]}               
          btnText="Learn More"
          btnUrl="/about"
        />
      </section>

      {/* This area below is Projects numbers data */}
      {/* <div className="container">
        <Spacing lg="125" md="70" />
        <FunFact data={funfactData} />
      </div> */}

      {/* This area below is Case Study */}
      {/* <section>
        <Spacing lg="118" md="70" />
        <div className="container">
          <SectionHeading
            title="Real world solutions successful <br />case studies in Zivan"
            subTitle="Case Study"
          />
          <Spacing lg="85" md="45" />
        </div>
        <CaseStudy data={caseStudyData} />
        <Spacing lg="100" md="60" />
        <div className="container">
          <div className="text-center">
            <Button btnText="More Case Study" btnUrl="/" />
          </div>
        </div>
      </section> */}

      {/* This area below is Video section */}
      {/* <div className="container">
        <Spacing lg="126" md="70" />
        <VideoModal
          // videoSrc="https://www.youtube.com/embed/VcaAVWtP48A"
          bgUrl="/images/home-page/hero_video_bg_1.png"
          title="Our Digital Wizards are committed to transforming your marketing ideas into reality."
          // title="Our Creative Minds blend marketing and technology to bring your visions to life."
          // title="Our Digital Alchemists turn your marketing concepts into powerful realities."
          // title="Our Innovative Team turns your digital marketing dreams into engaging experiences."
          // title="Our Expert Marketers and Technologists craft your ideas into impactful digital campaigns."
        />
      </div> */}

      {/* This area below is TestimonialSlider */}
      {/* <TestimonialSlider
        layeredImages={[
          '/images/digital-marketing/layer_img_1.png',
          '/images/digital-marketing/layer_img_2.png',
          '/images/digital-marketing/layer_img_3.png',
          '/images/digital-marketing/layer_img_4.png',
          '/images/digital-marketing/layer_img_5.png',
        ]}
        data={testimonialData}
      /> */}

      {/* This area below is Awards Section */}
      {/* <section className="cs_primary_bg cs_shape_animation_2">
        <Spacing lg="143" md="75" />
        <div className="cs_shape_1 position-absolute">
          <svg
            width={65}
            height={64}
            viewBox="0 0 65 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.5">
              <path
                d="M62.4554 25.9314C55.6838 19.6081 40.1618 12.4752 32.1637 20.1537C41.7609 21.9206 53.2379 29.2392 48.3751 39.1677C45.1712 45.7019 38.7353 45.7177 33.3337 41.995C27.338 37.8739 25.7108 31.2667 27.4596 24.5962C26.5312 24.5866 25.6039 24.6605 24.6889 24.8172C9.80991 27.7447 14.0713 47.6353 20.9187 55.948C22.4528 57.8045 19.7488 60.3159 18.1393 58.4837C7.86403 46.8126 6.49349 23.0691 25.5532 19.9295C26.8892 19.7254 28.2446 19.6801 29.5912 19.7945C36.9845 9.42053 56.5698 17.4866 64.055 24.4366C65.1096 25.4175 63.4831 26.8926 62.4554 25.9314ZM33.9938 39.0327C38.3927 42.4636 44.2429 40.8527 44.3919 34.8698C44.6036 28.2263 35.7464 25.0921 29.1457 24.655C27.1454 29.9313 29.4427 35.4836 33.9938 39.0327Z"
                fill="#4F4747"
              />
            </g>
          </svg>
        </div>
        <div className="container">
          <SectionHeading
            title="Our prize achievement"
            subTitle="Awards"
            variantColor="cs_white_color"
          />
          <Spacing lg="85" md="45" />
          <Award data={awardData} />
        </div>
        <Spacing lg="150" md="80" />
      </section> */}

      {/* This area below is BLOG */}
      {/* <section className="cs_p76_full_width">
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading title="Some recent news" subTitle="Our Blog" />
          <Spacing lg="85" md="45" />
        </div>
        <PostCarousel data={postData} />
      </section> */}

      {/* This area below is FAQs */}
      <section>
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading title="Frequently asked question" subTitle="FAQs" />
          <Spacing lg="55" md="30" />
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <Accordion variant="cs_type_1" data={faqData} />
            </div>
          </div>
        </div>
        <Spacing lg="120" md="60" />
      </section>

      {/* This area below is Contact Us at end */}
      <section>
        <div className="container">
          <Cta
            title="Is there a specific project or goal that you have in mind?"
            btnText="Contact Us"
            btnUrl="/contact"
            bgUrl="/images/home-page/cta_bg.jpeg"
          />
        </div>
      </section>
      <Spacing lg="135" md="70" />
      {/* <Marquee text="We Create Design - Build App - Website - Branding - SEO" /> */}
      <Marquee text="We Create: Custom Web Development - Mobile App Development - Branding - Social Media Marketing - Video Campaigns - UI/UX Design - API Integration - Content Marketing - Email Marketing - Pay-Per-Click Advertising - SEO Services - Influencer Marketing - Analytics and Reporting - Conversion Rate Optimization - Reputation Management." />
      <Spacing lg="84" md="50" />

      {/* This area below is Brand List */}
      {/* <div className="container">
        <Brands data={darkMode ? brandListDark : brandList} />
      </div> */}
      {/* <Spacing lg="135" md="80" /> */}
    </>
  );
}
