import React from 'react';
import Spacing from '../Spacing';
import SectionHeadingStyle3 from '../SectionHeading/SectionHeadingStyle3';
import CtaStyle2 from '../Cta/CtaStyle2';
import AboutStyle6 from '../About/AboutStyle6';
import CardStyle3 from '../Card/CardStyle3';
import { pageTitle } from '../../helpers/PageTitle';

export default function ServiceDetailsPageUIUX() {
  pageTitle('UI/UX Design');
  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />

      {/* Hero Section */}
      <SectionHeadingStyle3
        title="Designing intuitive and engaging user experiences"
        subTitle="UI/UX Design"
        shape="shape_3"
      />
      <Spacing lg="75" md="60" />

      {/* About Section */}
      <div className="cs_service_info">
        <div className="container">
          <div className="row align-items-center cs_gap_y_40">
            <div className="col-lg-6">
              <div
                className="cs_service_info_thumb cs_bg_filed"
                style={{
                  backgroundImage:
                    'url("/images/others/uiux.jpg")',
                }}
              />
            </div>
            <div className="col-lg-6">
              <div className="row cs_gap_y_40">
                <div className="col-sm-6">
                  <CardStyle3
                    number="01"
                    title="User Research"
                    subTitle="Understanding user needs and behaviors to inform design decisions."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="02"
                    title="Wireframing"
                    subTitle="Creating wireframes to outline the structure and layout of the interface."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="03"
                    title="Prototyping"
                    subTitle="Developing interactive prototypes to test and refine user experiences."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="04"
                    title="Visual Design"
                    subTitle="Designing visually appealing and user-friendly interfaces."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="05"
                    title="Usability Testing"
                    subTitle="Conducting usability tests to identify and resolve user experience issues."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="06"
                    title="Interaction Design"
                    subTitle="Designing interactive elements to enhance user engagement."
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Spacing lg="150" md="80" />
      <AboutStyle6
        thumbnailSrc="/images/others/uiux2.jpg"
        title="What you will get from this service?"
        subTitle="Our UI/UX design services focus on creating intuitive and engaging user experiences. From user research to usability testing, we ensure your digital products are user-friendly and visually appealing."
        featureList={[
          'In-depth user research',
          'Comprehensive wireframing and prototyping',
          'Visually appealing and user-friendly designs',
          'Thorough usability testing and interaction design',
        ]}
        btnText2="Explore Mobile App Development"
        btnUrl2="/service/app"
        btnText3="Explore Web Development"
        btnUrl3="/service/web"
        btnText="More"
        btnUrl="/service"
      />
      <Spacing lg="50" md="80" />

      {/* CTA Section */}
      <div className="cs_height_140 cs_height_lg_70" />
      <CtaStyle2
        title="Is there a specific project or goal <br />that you have in mind?"
        btnText="Send Message"
        btnUrl="/contact"
      />
      <div className="cs_height_150 cs_height_lg_80" />
    </>
  );
}
