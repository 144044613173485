import React from 'react';
import Spacing from '../Spacing';
import SectionHeadingStyle3 from '../SectionHeading/SectionHeadingStyle3';
import AboutStyle4 from '../About/AboutStyle4';
// import FunFact from '../FunFact';
import AboutStyle5 from '../About/AboutStyle5';
import IconBoxStyle6 from '../IconBox/IconBoxStyle6';
import SectionHeadingStyle5 from '../SectionHeading/SectionHeadingStyle5';
// import SectionHeading from '../SectionHeading';
// import TeamSlider from '../Slider/TeamSlider';
import Marquee from '../Marquee';
// import Brands from '../Brands';
import { pageTitle } from '../../helpers/PageTitle';
// const funfactData = [
//   { title: 'Happy Customers', number: '22k' },
//   { title: 'Work’s Completed', number: '15k' },
//   { title: 'Skilled Team Members', number: '121' },
//   { title: 'Most Valuable Awards', number: '15' },
// ];

// const teamData = [
//   {
//     memberImg: '/images/studio-agency/team_1.jpeg',
//     memberName: 'James Berline',
//     memberDesignation: 'React Developer',
//     href: '/team/team-details',
//   },
//   {
//     memberImg: '/images/studio-agency/team_2.jpeg',
//     memberName: 'Bella Zubena',
//     memberDesignation: 'Graphic Designer',
//     href: '/team/team-details',
//   },
//   {
//     memberImg: '/images/studio-agency/team_3.jpeg',
//     memberName: 'Kemnei Alekzend',
//     memberDesignation: 'Digital Marketer',
//     href: '/team/team-details',
//   },
//   {
//     memberImg: '/images/studio-agency/team_4.jpeg',
//     memberName: 'Juliya Jesmine',
//     memberDesignation: 'UX Researcher',
//     href: '/team/team-details',
//   },
//   {
//     memberImg: '/images/studio-agency/team_1.jpeg',
//     memberName: 'James Berline',
//     memberDesignation: 'React Developer',
//     href: '/team/team-details',
//   },
//   {
//     memberImg: '/images/studio-agency/team_2.jpeg',
//     memberName: 'Bella Zubena',
//     memberDesignation: 'Graphic Designer',
//     href: '/team/team-details',
//   },
//   {
//     memberImg: '/images/studio-agency/team_3.jpeg',
//     memberName: 'Kemnei Alekzend',
//     memberDesignation: 'Digital Marketer',
//     href: '/team/team-details',
//   },
//   {
//     memberImg: '/images/studio-agency/team_4.jpeg',
//     memberName: 'Juliya Jesmine',
//     memberDesignation: 'UX Researcher',
//     href: '/team/team-details',
//   },
// ];

// const brandList = [
//   {
//     logoSrc: '/images/digital-marketing/brand_1.svg',
//     logoAlt: 'Brand',
//   },
//   {
//     logoSrc: '/images/digital-marketing/brand_2.svg',
//     logoAlt: 'Brand',
//   },
//   {
//     logoSrc: '/images/digital-marketing/brand_3.svg',
//     logoAlt: 'Brand',
//   },
//   {
//     logoSrc: '/images/digital-marketing/brand_4.svg',
//     logoAlt: 'Brand',
//   },
// ];
// const brandListDark = [
//   {
//     logoSrc: '/images/digital-marketing/brand_1_dark.svg',
//     logoAlt: 'Brand',
//   },
//   {
//     logoSrc: '/images/digital-marketing/brand_2_dark.svg',
//     logoAlt: 'Brand',
//   },
//   {
//     logoSrc: '/images/digital-marketing/brand_3_dark.svg',
//     logoAlt: 'Brand',
//   },
//   {
//     logoSrc: '/images/digital-marketing/brand_4_dark.svg',
//     logoAlt: 'Brand',
//   },
// ];

export default function AboutPage({ darkMode }) {
  pageTitle('About');
  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />

      {/* This area below is Hero Section */}
      <SectionHeadingStyle3
        title="Adding value to your business, <br>making it worthy"
        subTitle="About Us"
        variant="text-center"
        shape="shape_1"
      />
      <Spacing lg="75" md="60" />

      {/* This area below is Company Info */}
      <AboutStyle4
        thumbnailSrc="/images/digital-agency/about_1.jpeg"
        miniTitle="Company Info"
        title="Our Priorities Equal Your Need"
        subTitle="Our team excels in providing cutting-edge digital marketing and tech solutions tailored to your needs. We leverage advanced lead scoring techniques and comprehensive sales intelligence to drive results. Explore our custom web and app development services, SOPs, and templates designed to streamline your processes. Elevate your online presence with targeted ads, expert SEO strategies, and innovative digital tools. Combining imaginative thinking, consumer behavior insights, and data-driven design with advanced technology, we deliver unparalleled brand experiences that set your business apart."
        btnText="See Our Services"
        btnUrl="/service"
      />
      <Spacing lg="125" md="70" />

      {/* This area below is Projects numbers data */}
      {/* <div className="container">
        <FunFact data={funfactData} />
      </div>
      <Spacing lg="125" md="70" /> */}

      {/* This area below is What We Do */}
      <AboutStyle5
        variant="cs_type_1"
        thumbnailSrc="/images/digital-agency/about_2.jpeg"
        miniTitle="What We Do"
        title="Your Partner in Digital Success"
        subTitle="We provide cutting-edge digital marketing and tech solutions tailored to your needs. By leveraging advanced lead scoring techniques and comprehensive sales intelligence, we drive exceptional results. Our custom web and app development services, along with SOPs and templates, streamline your processes. Elevate your online presence with targeted ads, expert SEO strategies, and innovative digital tools. With a blend of imaginative thinking, consumer behavior insights, and data-driven design, we deliver unparalleled brand experiences."
        progressBarList={[
          { title: 'Digital Marketing', percentage: '75' },
          { title: 'Brand Strategy', percentage: '85' },
          { title: 'Competitor Analysis', percentage: '95' },
          { title: 'Digital Solutions', percentage: '95' },
        ]}
        salesTitle="Sales Increase"
        groth="25"
      />
      <Spacing lg="150" md="80" />

      {/* This area below is How We Work */}
      <section className="cs_primary_bg">
        <Spacing lg="140" md="70" />
        <div className="container">
          <SectionHeadingStyle5 title="How we work" />
          <Spacing lg="85" md="45" />
          <div className="cs_working_process_wrap cs_center">
            <div className="cs_working_process">
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_1.png"
                  iconSrc="/images/icons/search.svg"
                  title="Research"
                  subTitle="Conduct thorough research to understand market trends, audience behavior, and competitive landscape."
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_2.png"
                  iconSrc="/images/icons/idea.svg"
                  title="Idea Generation"
                  subTitle="Brainstorm and develop innovative ideas tailored to meet your business goals and resonate with your target audience."
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_3.png"
                  iconSrc="/images/icons/gear.svg"
                  title="Implementation"
                  subTitle="Execute the strategies and ideas with precision, using advanced technology and best practices."
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_4.png"
                  iconSrc="/images/icons/optimization_2.png"
                  title="Optimization"
                  subTitle="Continuously monitor and optimize the campaigns and solutions to ensure maximum performance and results."
                />
              </div>
              <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_5.png"
                  iconSrc="/images/icons/report.png"
                  title="Reporting"
                  subTitle="Provide detailed reports and insights to measure success and identify areas for improvement."
                />
              </div>
            </div>
          </div>
        </div>
        <Spacing lg="150" md="80" />
      </section>

      {/* This area below is Our Team */}
      {/* <section className="cs_p76_full_width">
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading
            title="Meet our experts team behind Opeyn"
            subTitle="Our Team"
          />
          <Spacing lg="85" md="45" />
        </div>
        <TeamSlider data={teamData} />
      </section> */}
      <Spacing lg="135" md="70" />

      {/* <Marquee text="We Create Design - Build App - Website - Branding - SEO" /> */}
      <Marquee text="We Create: Custom Web Development - Mobile App Development - Branding - Social Media Marketing - Video Campaigns - UI/UX Design - API Integration - Content Marketing - Email Marketing - Pay-Per-Click Advertising - SEO Services - Influencer Marketing - Analytics and Reporting - Conversion Rate Optimization - Reputation Management" />
      {/* <Spacing lg="84" md="50" /> */}

      {/* This area below is Brand List */}
      {/* <div className="container">
        <Brands data={darkMode ? brandListDark : brandList} />
      </div> */}
      <Spacing lg="135" md="80" />
    </>
  );
}
