import React from 'react';
import Spacing from '../Spacing';
import SectionHeadingStyle3 from '../SectionHeading/SectionHeadingStyle3';
import CtaStyle2 from '../Cta/CtaStyle2';
import AboutStyle6 from '../About/AboutStyle6';
import CardStyle3 from '../Card/CardStyle3';
import { pageTitle } from '../../helpers/PageTitle';

export default function ServiceDetailsPageSEO() {
  pageTitle('SEO Services');
  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />

      {/* Hero Section */}
      <SectionHeadingStyle3
        title="Enhance your website's visibility and search engine ranking"
        subTitle="SEO Services"
        shape="shape_3"
      />
      <Spacing lg="75" md="60" />

      {/* About Section */}
      <div className="cs_service_info">
        <div className="container">
          <div className="row align-items-center cs_gap_y_40">
            <div className="col-lg-6">
              <div
                className="cs_service_info_thumb cs_bg_filed"
                style={{
                  backgroundImage:
                    'url("/images/others/seo.jpg")',
                }}
              />
            </div>
            <div className="col-lg-6">
              <div className="row cs_gap_y_40">
                <div className="col-sm-6">
                  <CardStyle3
                    number="01"
                    title="Keyword Research"
                    subTitle="Identifying the most effective keywords to target your audience."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="02"
                    title="On-Page SEO"
                    subTitle="Optimizing your website's content and structure for better search engine visibility."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="03"
                    title="Technical SEO"
                    subTitle="Ensuring your website meets all technical requirements for search engines."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="04"
                    title="Link Building"
                    subTitle="Building high-quality backlinks to improve your website's authority."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="05"
                    title="Local SEO"
                    subTitle="Optimizing your online presence to attract more business from local searches."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="06"
                    title="SEO Audits"
                    subTitle="Conducting comprehensive audits to identify and fix SEO issues."
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Spacing lg="150" md="80" />
      <AboutStyle6
        thumbnailSrc="/images/others/seo2.jpg"
        title="What you will get from this service?"
        subTitle="Our SEO services are designed to improve your website's visibility and ranking on search engines. We use a strategic approach to optimize various elements of your site, ensuring it attracts more organic traffic and achieves higher rankings."
        featureList={[
          'Effective keyword research',
          'On-page and technical SEO',
          'High-quality link building',
          'Comprehensive SEO audits',
        ]}
        btnText2="Explore On Page Optimization"
        btnUrl2="/service/optimization"
        btnText3="Explore Pay-Per-Click Advertising"
        btnUrl3="/service/ppc"
        btnText="More"
        btnUrl="/service"
      />
      <Spacing lg="50" md="80" />

      {/* CTA Section */}
      <div className="cs_height_140 cs_height_lg_70" />
      <CtaStyle2
        title="Is there a specific project or goal <br />that you have in mind?"
        btnText="Send Message"
        btnUrl="/contact"
      />
      <div className="cs_height_150 cs_height_lg_80" />
    </>
  );
}
