import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './components/Pages/Home';
import MarketingAgencyPage from './components/Pages/MarketingAgencyPage';
import ServicePage from './components/Pages/ServicePage';
import AboutPage from './components/Pages/AboutPage';
import ContactPage from './components/Pages/ContactPage';
import Layout2 from './components/Layout/Layout2';
import TechSolutionsPage from './components/Pages/TechSolutionsPage';
import TermsConditionPage from './components/Pages/TermsConditionPage';
import PrivacyPolicy from './components/Pages/PrivacyPolicy';
// import StudioAgencyPage from './components/Pages/StudioAgencyPage';
// import DigitalAgencyPage from './components/Pages/DigitalAgencyPage';
// import BlogPage from './components/Pages/BlogPage';
// import BlogListPage from './components/Pages/BlogListPage';
// import BlogDetailsPage from './components/Pages/BlogDetailsPage';
import OurWorks from './components/Pages/PortfolioPage';
// import PortfolioDetailsPage from './components/Pages/PortfolioDetailsPage';
// import CaseStudyDetailsPage from './components/Pages/CaseStudyDetailsPage';
// import TeamPage from './components/Pages/TeamPage';
// import TeamDetailsPage from './components/Pages/TeamDetailsPage';
// import Shop from './components/Pages/Shop';
// import ProductDetails from './components/Pages/Shop/ProductDetails';
// import Cart from './components/Pages/Shop/Cart';
// import Checkout from './components/Pages/Shop/Checkout';
// import Success from './components/Pages/Shop/Success';
// import Wishlist from './components/Pages/Shop/Wishlist';
// import Layout3 from './components/Layout/Layout3';
import ErrorPage from './components/Pages/ErrorPage';
import ServiceDetailsRouter from './components/ServiceDetailsRouter';
import PricingPageRouter from './components/PricingPageRouter';
import Metadata from './components/Metadata';

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Metadata />
      <Routes>
        <Route path="/" element={<Layout darkMode />}>
          <Route path="digital-marketing" element={<MarketingAgencyPage darkMode />} />
          {/* <Route path="studio-agency" element={<StudioAgencyPage darkMode />} /> */}
          {/* <Route path="digital-agency" element={<DigitalAgencyPage darkMode />} /> */}
          <Route path="about" element={<AboutPage darkMode />} />
          <Route path="service" element={<ServicePage />} />
          <Route path="service/:serviceDetailsId" element={<ServiceDetailsRouter />} />
          <Route path="pricing/:pricingPageId" element={<PricingPageRouter />} />
          {/* <Route path="blog" element={<BlogPage />} />
          <Route path="blog-list" element={<BlogListPage />} />
          <Route path="blog/:blogDetailsId" element={<BlogDetailsPage />} />*/}
          <Route path="works" element={<OurWorks />} />
          {/* <Route path="portfolio/:portfolioDetailsId" element={<PortfolioDetailsPage />} />
          <Route path="case-study-details" element={<CaseStudyDetailsPage />} />
          <Route path="team" element={<TeamPage />} />
          <Route path="team/:teamDetailsId" element={<TeamDetailsPage />} /> */}
          <Route path="contact" element={<ContactPage />} />
          <Route path="t&c" element={<TermsConditionPage />} />
          <Route path="privacypolicy" element={<PrivacyPolicy />} />
        </Route>
        <Route path="/" element={<Layout2 darkMode />}>
          <Route index element={<Home />} />
          <Route path="tech-solutions" element={<TechSolutionsPage />} />
        </Route>
        {/* <Route path="/" element={<Layout3 darkMode />}>
          <Route path="shop" element={<Shop />} />
          <Route path="shop/:productId" element={<ProductDetails />} />
          <Route path="shop/cart" element={<Cart />} />
          <Route path="shop/checkout" element={<Checkout />} />
          <Route path="shop/success" element={<Success />} />
          <Route path="shop/wishlist" element={<Wishlist />} />
        </Route> */}
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
}

export default App;