import React from 'react';
import Hero from '../Hero';
import Spacing from '../Spacing';
// import FunFact from '../FunFact';
import About from '../About';
import WhyChose from '../WhyChose';
import Service from '../Service';
// import Portfolio from '../Portfolio';
// import Button from '../Button';
import SectionHeading from '../SectionHeading';
// import Award from '../Award';
import Accordion from '../Accordion';
import Cta from '../Cta';
// import TestimonialSlider from '../Slider/TestimonialSlider';
// import PostCarousel from '../Slider/PostCarousel';
import { pageTitle } from '../../helpers/PageTitle';

// const funfactData = [
//   { title: 'Happy Customers', number: '22k' },
//   { title: 'Work’s Completed', number: '15k' },
//   { title: 'Skilled Team Members', number: '121' },
//   { title: 'Most Valuable Awards', number: '15' },
// ];
const whyChoseFeatureData = [
  {
    title: 'Expert Web and App Development Team',
    content:
      'Our team excels in creating high-performing, user-friendly web and app solutions that drive business growth and enhance user engagement.',
  },
  {
    title: 'Innovative Digital Marketing Strategies',
    content:
      'We implement cutting-edge digital marketing techniques, including advanced lead scoring and comprehensive sales intelligence, to boost your online presence.',
  },
  // {
  //   title: 'AI-Powered Content Creation',
  //   content:
  //     'Utilizing AI, we produce high-quality, engaging content that resonates with your target audience and enhances your brand image.',
  // },
  {
    title: 'Comprehensive Branding Services',
    content:
      'From logo design to comprehensive brand strategy, our services ensure your brand stands out and leaves a lasting impression.',
  },
  {
    title: 'Advanced SEO Techniques',
    content:
      'Our expert SEO strategies optimize your website to rank higher on search engines, driving more organic traffic to your site.',
  },
];

const serviceListData = [
  {
    title: 'Custom Web Development',
    subtitle:
      'We create tailored, responsive websites that enhance user experience and optimize performance, ensuring your online presence stands out.',
    imgUrl: '/images/home-page/web.jpg',
    href: '/service/web',
  },
  {
    title: 'Mobile App Development',
    subtitle:
      'Our team develops seamless, high-quality mobile applications for all devices, providing exceptional user experiences and functionality.',
    imgUrl: '/images/home-page/app.jpg',
    href: '/service/app',
  },
  {
    title: 'Branding',
    subtitle:
      'From logo design to comprehensive brand strategy, our branding services ensure your brand makes a lasting impression and stands out in the market.',
    imgUrl: '/images/home-page/branding.jpg',
    href: '/service/branding',
  },
  // {
  //   title: 'Social Ad Campaigns',
  //   subtitle:
  //     'We design and execute targeted social ad campaigns to boost your online visibility, engage your audience, and drive conversions.',
  //   imgUrl: '/images/home-page/service_10.jpeg',
  //   href: '/service',
  // },
  // {
  //   title: 'UI/UX Design',
  //   subtitle:
  //     'Our UI/UX design services craft visually appealing and intuitive interfaces that enhance user engagement and satisfaction.',
  //   imgUrl: '/images/home-page/service_11.jpeg',
  //   href: '/service',
  // },
  {
    title: 'SEO and Digital Marketing',
    subtitle:
      'Our expert SEO strategies and digital marketing campaigns optimize your online presence, drive traffic, and increase conversions.',
    imgUrl: '/images/home-page/dm.jpg',
    href: '/service/seo',
  },
];

// const portfolioData = [
//   {
//     href: '/portfolio/portfolio-details',
//     imgUrl: '/images/home-page/portfolio_1.jpeg',
//     title: 'Awesome colorful artwork',
//     btnText: 'See Project',
//   },
//   {
//     href: '/portfolio/portfolio-details',
//     imgUrl: '/images/home-page/portfolio_2.jpeg',
//     title: 'Admin dashboard UI design',
//     btnText: 'See Project',
//   },
//   {
//     href: '/portfolio/portfolio-details',
//     imgUrl: '/images/home-page/portfolio_3.jpeg',
//     title: 'Product designing with brand',
//     btnText: 'See Project',
//   },
//   {
//     href: '/portfolio/portfolio-details',
//     imgUrl: '/images/home-page/portfolio_4.jpeg',
//     title: 'Kids education website design',
//     btnText: 'See Project',
//   },
// ];

// const awardData = [
//   {
//     brand: 'Behance',
//     title: 'UI/UX design of the month',
//     subTitle:
//       'Accusamus et iusto odio dignissimos ducimus qui blanditiis fedarals praesentium voluptatum deleniti atque corrupti quos dolores',
//     date: 'December 12, 2023',
//     awardImgUrl: '/images/home-page/award_img_1.svg',
//   },
//   {
//     brand: 'Awwwards',
//     title: 'CSS awards design',
//     subTitle:
//       'Accusamus et iusto odio dignissimos ducimus qui blanditiis fedarals praesentium voluptatum deleniti atque corrupti quos dolores',
//     date: 'January 05, 2022',
//     awardImgUrl: '/images/home-page/award_img_2.svg',
//   },
//   {
//     brand: 'Google',
//     title: 'Website of the day',
//     subTitle:
//       'Accusamus et iusto odio dignissimos ducimus qui blanditiis fedarals praesentium voluptatum deleniti atque corrupti quos dolores',
//     date: 'March 20, 2021',
//     awardImgUrl: '/images/home-page/award_img_3.svg',
//   },
// ];

// const testimonialData = [
//   {
//     text: 'Zivans Motion Graphics did an excellent job on my video related projects. The motion graphics added an extra layer of polish and really brought the video to life. I highly recommend their high quality services and work.',
//     avatarName: 'Ansari Patron',
//     avatarDesignation: 'CEO at Delta',
//   },
//   {
//     text: 'Zivans Motion Graphics did an excellent job on my video related projects. The motion graphics added an extra layer of polish and really brought the video to life. I highly recommend their high quality services and work.',
//     avatarName: 'Jhon Doe',
//     avatarDesignation: 'Manager at Delta',
//   },
//   {
//     text: 'Zivans Motion Graphics did an excellent job on my video related projects. The motion graphics added an extra layer of polish and really brought the video to life. I highly recommend their high quality services and work.',
//     avatarName: 'Ramatam Coo',
//     avatarDesignation: 'MD at Delta',
//   },
// ];

const faqData = [
  {
    title: '01. How can I contact you for your services?',
    content:
      'You can reach us via email at connect@opeyn.com for any inquiries or to discuss your project requirements. We look forward to helping you achieve your digital goals.',
  },
  {
    title: '02. What types of services do you provide?',
    content:
      'We offer a range of services including Custom Web Development, Mobile App Development, Branding, Social Ad Campaigns, UI/UX Design, and SEO & Digital Marketing. Our comprehensive solutions are tailored to meet your specific business needs.',
  },
  {
    title: '03. What are the different stages of the working process?',
    content:
      'Our process includes an initial consultation to understand your needs, followed by planning and strategy development, design and development, testing and quality assurance, and finally, deployment and ongoing support. We ensure each stage is carefully managed to deliver the best results.',
  },
  {
    title: '04. What is the difference between web development and mobile app development?',
    content:
      'Web development focuses on creating websites that are accessible via internet browsers, whereas mobile app development involves creating applications specifically designed for mobile devices. Both services aim to provide seamless user experiences, but they cater to different platforms and user interactions.',
  },
  {
    title: '05. How can I proceed with payment after the project is completed?',
    content:
      'Once your project is completed to your satisfaction, we will provide you with a detailed invoice. Payment can be made through various methods including bank transfer, credit card, or other electronic payment options. We ensure a smooth and secure payment process.',
  },
];

// const postData = [
//   {
//     thumbnailSrc: '/images/home-page/post_1.jpeg',
//     title: 'How to keep fear from ruining your art business with confident',
//     date: '07 Mar 2023',
//     url: '/blog/blog-details',
//   },
//   {
//     thumbnailSrc: '/images/home-page/post_2.jpeg',
//     title: 'Artistic mind will be great for creation anything',
//     date: '22 Apr 2023',
//     url: '/blog/blog-details',
//   },
//   {
//     thumbnailSrc: '/images/home-page/post_3.jpeg',
//     title: 'AI will take over all job for human within few years',
//     date: '13 May 2023',
//     url: '/blog/blog-details',
//   },
//   {
//     thumbnailSrc: '/images/home-page/post_4.jpeg',
//     title: 'Your agency need to replace some artistic mind people',
//     date: '15 Mar 2023',
//     url: '/blog/blog-details',
//   },
//   {
//     thumbnailSrc: '/images/home-page/post_1.jpeg',
//     title: 'How to keep fear from ruining your art business with confident',
//     date: '07 Mar 2023',
//     url: '/blog/blog-details',
//   },
//   {
//     thumbnailSrc: '/images/home-page/post_2.jpeg',
//     title: 'Artistic mind will be great for creation anything',
//     date: '22 Apr 2023',
//     url: '/portfolio/portfolio-details',
//   },
//   {
//     thumbnailSrc: '/images/home-page/post_3.jpeg',
//     title: 'AI will take over all job for human within few years',
//     date: '13 May 2023',
//     url: '/portfolio/portfolio-details',
//   },
//   {
//     thumbnailSrc: '/images/home-page/post_4.jpeg',
//     title: 'Your agency need to replace some artistic mind people',
//     date: '15 Mar 2023',
//     url: '/portfolio/portfolio-details',
//   },
// ];

export default function Home() {
  pageTitle('Home');
  return (
    <>
      {/* This area below is Hero Section */}
      <Hero
        title={[
          'Innovative Digital Marketing Solutions',
          'Expert Web and App Development',
          'Driving Business Growth with Technology',
          'Your Partner in Digital Transformation',
        ]}
        subtitle="Empowering Your Brand with Cutting-Edge Technology and Strategic Marketing."
        videoSrc="/M38.mp4"
        // videoSrc="https://www.youtube.com/embed/VcaAVWtP48A"
        bgUrl="/hero_video_bg_1.png"
      />
      <Spacing lg="125" md="70" />

      {/* This area below is Projects numbers data */}
      {/* <div className="container">
        <FunFact data={funfactData} />
      </div> */}
      {/* <Spacing lg="125" md="70" /> */}

      {/* This area below is Who We Are */}
      <About
        thumbnail="/images/home-page/about_1.jpeg"
        uperTitle="Who We Are"
        title="Our Priorities Equal Your Need"
        subTitle="We provide cutting-edge digital marketing and tech solutions tailored to your needs. Leverage our advanced lead scoring techniques and comprehensive sales intelligence. Explore our custom web and app development services, SOPs, and templates designed to streamline your processes. Elevate your online presence with targeted ads, expert SEO strategies, and innovative digital tools."
        featureList={[
          'Innovative Marketing Tools',
          'Strategic Market Solutions',
          'Advanced Web and App Development',
          'Comprehensive Branding Services',
        ]}
        btnText="Learn More"
        btnUrl="/about"
      />
      <Spacing lg="185" md="75" />

      {/* This area below is Why Choose Us */}
      <WhyChose
        sectionTitle="Transforming Ideas into Reality"
        // sectionTitle="We have depth of market knowledge"
        // sectionTitle="Mastery in Digital Solutions"
        // sectionTitle="Pioneering Digital Excellence"
        // sectionTitle="Leading with Innovation and Expertise"
        // sectionTitle="Your Partner in Digital Success"
        sectionSubTitle="Why Choose Us"
        whyChoseFeatureData={whyChoseFeatureData}
        thumbnailSrc="/images/home-page/why_choose_us_img_3.jpeg"
      />
      <Spacing lg="150" md="80" />

      {/* This area below is Core Services */}
      <section className="cs_primary_bg">
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading
            title="Our core services"
            subTitle="Services"
            variantColor="cs_white_color"
          />
          <Spacing lg="45" md="30" />
        </div>
        <div className="container">
          <Service
            sectionTitle="Our core services"
            sectionSubTitle="Services"
            data={serviceListData}
          />
          <Spacing lg="135" md="65" />
        </div>
      </section>

      {/* This area below is Projects Portfolio */}
      {/* <section>
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading title="Some featured work" subTitle="Portfolio" />
          <Spacing lg="85" md="45" />
          <Portfolio data={portfolioData} />
          <Spacing lg="26" md="30" />
          <div className="text-center">
            <Button btnText="See All Project" btnUrl="/portfolio" />
          </div>
        </div>
        <Spacing lg="150" md="80" />
      </section> */}

      {/* This area below is Awards Section */}
      {/* <section className="cs_primary_bg cs_shape_animation_2">
        <Spacing lg="143" md="75" />
        <div className="cs_shape_1 position-absolute">
          <svg
            width={65}
            height={64}
            viewBox="0 0 65 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.5">
              <path
                d="M62.4554 25.9314C55.6838 19.6081 40.1618 12.4752 32.1637 20.1537C41.7609 21.9206 53.2379 29.2392 48.3751 39.1677C45.1712 45.7019 38.7353 45.7177 33.3337 41.995C27.338 37.8739 25.7108 31.2667 27.4596 24.5962C26.5312 24.5866 25.6039 24.6605 24.6889 24.8172C9.80991 27.7447 14.0713 47.6353 20.9187 55.948C22.4528 57.8045 19.7488 60.3159 18.1393 58.4837C7.86403 46.8126 6.49349 23.0691 25.5532 19.9295C26.8892 19.7254 28.2446 19.6801 29.5912 19.7945C36.9845 9.42053 56.5698 17.4866 64.055 24.4366C65.1096 25.4175 63.4831 26.8926 62.4554 25.9314ZM33.9938 39.0327C38.3927 42.4636 44.2429 40.8527 44.3919 34.8698C44.6036 28.2263 35.7464 25.0921 29.1457 24.655C27.1454 29.9313 29.4427 35.4836 33.9938 39.0327Z"
                fill="#4F4747"
              />
            </g>
          </svg>
        </div>
        <div className="container">
          <SectionHeading
            title="Our prize achievement"
            subTitle="Awards"
            variantColor="cs_white_color"
          />
          <Spacing lg="85" md="45" />
          <Award data={awardData} />
        </div>
        <Spacing lg="150" md="80" />
      </section> */}

      {/* This area below is TestimonialSlider */}
      {/* <TestimonialSlider
        layeredImages={[
          '/images/home-page/layer_img_1.jpeg',
          '/images/home-page/layer_img_2.jpeg',
          '/images/home-page/layer_img_3.jpeg',
          '/images/home-page/layer_img_4.jpeg',
          '/images/home-page/layer_img_5.jpeg',
        ]}
        data={testimonialData}
      /> */}

      <Spacing lg="143" md="75" />
      {/* This area below is Contact Us at end */}
      <section>
        <div className="container">
          <Cta
            title="Is there a specific project or goal that you have in mind?"
            btnText="Contact Us"
            btnUrl="/contact"
            bgUrl="/images/home-page/cta_bg.jpeg"
          />
        </div>
      </section>

      {/* This area below is BLOG */}
      {/* <section className="cs_p76_full_width">
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading title="Some recent news" subTitle="Our Blog" />
          <Spacing lg="85" md="45" />
        </div>
        <PostCarousel data={postData} />
      </section> */}

      {/* This area below is FAQs */}
      <section>
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading title="Frequently asked question" subTitle="FAQs" />
          <Spacing lg="55" md="30" />
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <Accordion variant="cs_type_1" data={faqData} />
            </div>
          </div>
        </div>
        <Spacing lg="120" md="50" />
      </section>
    </>
  );
}
