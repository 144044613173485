import React from 'react';
import { useParams } from 'react-router-dom';
// import PricingPageEmail from '../Pages/PricingPageEmail';
import PricingPageWeb from '../Pages/PricingPageWeb';
import PricingPageApp from '../Pages/PricingPageApp';
// import PricingPageBranding from '../Pages/PricingPageBranding';
// import PricingPageOptimization from '../Pages/PricingPageOptimization';
// import PricingPageSocial from '../Pages/PricingPageSocial';
// import PricingPageContent from '../Pages/PricingPageContent';
// import PricingPageSEO from '../Pages/PricingPageSEO';
// import PricingPagePPC from '../Pages/PricingPagePPC';
// import PricingPageInfluencer from '../Pages/PricingPageInfluencer';
// import PricingPageAnalytics from '../Pages/PricingPageAnalytics';
// import PricingPageCRO from '../Pages/PricingPageCRO';
// import PricingPageReputation from '../Pages/PricingPageReputation';
// import PricingPageAPI from '../Pages/PricingPageAPI';
// import PricingPageUIUX from '../Pages/PricingPageUIUX';
import ErrorPage from '../Pages/ErrorPage';

export default function PricingPageRouter() {
  const { pricingPageId } = useParams();

  // Conditional rendering based on pricingPageId
  switch (pricingPageId) {
    // case 'pricing-email':
    //   return <PricingPageEmail />;
    case 'pricing-web':
      return <PricingPageWeb />;
    case 'pricing-app':
      return <PricingPageApp />;
    // case 'pricing-branding':
    //   return <PricingPageBranding />;
    // case 'pricing-optimization':
    //   return <PricingPageOptimization />;
    // case 'pricing-social':
    //   return <PricingPageSocial />;
    // case 'pricing-content':
    //   return <PricingPageContent />;
    // case 'pricing-seo':
    //   return <PricingPageSEO />;
    // case 'pricing-ppc':
    //   return <PricingPagePPC />;
    // case 'pricing-influencer':
    //   return <PricingPageInfluencer />;
    // case 'pricing-analytics':
    //   return <PricingPageAnalytics />;
    // case 'pricing-cro':
    //   return <PricingPageCRO />;
    // case 'pricing-reputation':
    //   return <PricingPageReputation />;
    // case 'pricing-api':
    //   return <PricingPageAPI />;
    // case 'pricing-uiux':
    //   return <PricingPageUIUX />;
    default:
      return <ErrorPage />;
  }
}
