import React from 'react';
import { useParams } from 'react-router-dom';
// import ServiceDetailsPage from '../Pages/ServiceDetailsPage';
import ServiceDetailsPageEmail from '../Pages/ServiceDetailsPageEmail';
import ServiceDetailsPageWeb from '../Pages/ServiceDetailsPageWeb';
import ServiceDetailsPageApp from '../Pages/ServiceDetailsPageApp';
import ServiceDetailsPageBranding from '../Pages/ServiceDetailsPageBranding';
import ServiceDetailsPageOptimization from '../Pages/ServiceDetailsPageOptimization';
import ServiceDetailsPageSocial from '../Pages/ServiceDetailsPageSocial';
import ServiceDetailsPageContent from '../Pages/ServiceDetailsPageContent';
import ServiceDetailsPageSEO from '../Pages/ServiceDetailsPageSEO';
import ServiceDetailsPagePPC from '../Pages/ServiceDetailsPagePPC';
import ServiceDetailsPageInfluencer from '../Pages/ServiceDetailsPageInfluencer';
// import LinkedinPage from '../Pages/LinkedInPage';
import ServiceDetailsPageAnalytics from '../Pages/ServiceDetailsPageAnalytics';
import ServiceDetailsPageCRO from '../Pages/ServiceDetailsPageCRO';
import ServiceDetailsPageReputation from '../Pages/ServiceDetailsPageReputation';
import ServiceDetailsPageAPI from '../Pages/ServiceDetailsPageAPI';
import ServiceDetailsPageUIUX from '../Pages/ServiceDetailsPageUIUX';
import ErrorPage from '../Pages/ErrorPage';

export default function ServiceDetailsRouter() {
  const { serviceDetailsId } = useParams();

  // Conditional rendering based on serviceDetailsId
  switch (serviceDetailsId) {
    case 'content':
      return <ServiceDetailsPageContent />;
    case 'email':
      return <ServiceDetailsPageEmail />;
    case 'web':
      return <ServiceDetailsPageWeb />;
    case 'app':
      return <ServiceDetailsPageApp />;
    case 'branding':
      return <ServiceDetailsPageBranding />;
    case 'optimization':
      return <ServiceDetailsPageOptimization />;
    case 'social':
      return <ServiceDetailsPageSocial />;
    case 'seo':
      return <ServiceDetailsPageSEO />;
    case 'ppc':
      return <ServiceDetailsPagePPC />;
    case 'influencer':
      return <ServiceDetailsPageInfluencer />;
    // case 'linkedin':
    //   return <LinkedinPage />;
    case 'analytics':
      return <ServiceDetailsPageAnalytics />;
    case 'cro':
      return <ServiceDetailsPageCRO />;
    case 'reputation':
      return <ServiceDetailsPageReputation />;
    case 'api':
      return <ServiceDetailsPageAPI />;
    case 'uiux':
      return <ServiceDetailsPageUIUX />;
    default:
      return <ErrorPage />;
  }
}
