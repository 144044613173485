import React from 'react';
import Spacing from '../Spacing';
import SectionHeadingStyle3 from '../SectionHeading/SectionHeadingStyle3';
import Portfolio from '../Portfolio';
import CtaStyle2 from '../Cta/CtaStyle2';
import { pageTitle } from '../../helpers/PageTitle';
// const portfolioData = [
//   {
//     href: '/portfolio/portfolio-details',
//     imgUrl: '/images/home-page/portfolio_1.jpeg',
//     title: 'Awesome colorful artwork',
//     btnText: 'See Project',
//   },
//   {
//     href: '/portfolio/portfolio-details',
//     imgUrl: '/images/home-page/portfolio_2.jpeg',
//     title: 'Admin dashboard UI design',
//     btnText: 'See Project',
//   },
//   {
//     href: '/portfolio/portfolio-details',
//     imgUrl: '/images/home-page/portfolio_3.jpeg',
//     title: 'Product designing with brand',
//     btnText: 'See Project',
//   },
//   {
//     href: '/portfolio/portfolio-details',
//     imgUrl: '/images/home-page/portfolio_4.jpeg',
//     title: 'Kids education website design',
//     btnText: 'See Project',
//   },
//   {
//     href: '/portfolio/portfolio-details',
//     imgUrl: '/images/home-page/portfolio_5.jpeg',
//     title: 'Portable device UI/UX design',
//     btnText: 'See Project',
//   },
//   {
//     href: '/portfolio/portfolio-details',
//     imgUrl: '/images/home-page/portfolio_6.jpeg',
//     title: 'Social app design for IOS device',
//     btnText: 'See Project',
//   },
//   {
//     href: '/portfolio/portfolio-details',
//     imgUrl: '/images/home-page/portfolio_7.jpeg',
//     title: '3d table light lamp rendering',
//     btnText: 'See Project',
//   },
//   {
//     href: '/portfolio/portfolio-details',
//     imgUrl: '/images/home-page/portfolio_8.jpeg',
//     title: 'Old telephone 3d new model',
//     btnText: 'See Project',
//   },
// ];

const portfolioData = [
  {
    href: 'https://zuesly.vercel.app/',
    imgUrl: '/images/home-page/zuesly.png',
    title: 'Zuesly',
    btnText: 'See Project',
  },
  {
    href: 'https://plans-techy.vercel.app/',
    imgUrl: '/images/home-page/plans-techy.png',
    title: 'Plans-Techy',
    btnText: 'See Project',
  },
  {
    href: 'https://frontier-react-js.vercel.app/',
    imgUrl: '/images/home-page/frontier.png',
    title: 'Frontier',
    btnText: 'See Project',
  },
  {
    href: 'https://limo-content-generation.vercel.app/',
    imgUrl: '/images/home-page/limo-content-generation.png',
    title: 'Limo-content-generation',
    btnText: 'See Project',
  },
  {
    href: 'https://marketing-react-js.vercel.app/',
    imgUrl: '/images/home-page/marketing.png',
    title: 'Marketing Reactjs',
    btnText: 'See Project',
  },
  {
    href: 'https://secure-we-cyber-secutity.vercel.app/',
    imgUrl: '/images/home-page/secureWe.png',
    title: 'SecureWe',
    btnText: 'See Project',
  },
  {
    href: 'https://god-bt-react.vercel.app/',
    imgUrl: '/images/home-page/godbt.png',
    title: 'GodBT React Admin Template',
    btnText: 'See Project',
  },
  {
    href: 'https://jackinn.vercel.app/',
    imgUrl: '/images/home-page/jackinn.png',
    title: 'Jackinn',
    btnText: 'See Project',
  },
  {
    href: 'https://bobatae-shop.vercel.app/',
    imgUrl: '/images/home-page/bobataeShop.png',
    title: 'Shop',
    btnText: 'See Project',
  },
];


export default function PortfolioPage() {
  pageTitle('Our Works');
  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />
      <SectionHeadingStyle3
        title="See our creative works on Web development"
        // title="We have completed a series of noteworthy projects"
        subTitle="Our Works"
        shape="shape_3"
      />
      <Spacing lg="75" md="60" />
      <div className="container">
        <Portfolio data={portfolioData} />
        <div className="cs_height_75 cs_height_lg_40" />
        <CtaStyle2
          title="Is there a specific project or goal <br />that you have in mind?"
          btnText="Send Message"
          btnUrl="/contact"
        />
        <div className="cs_height_150 cs_height_lg_80" />
      </div>
    </>
  );
}
