import React from 'react';
import Spacing from '../Spacing';
import SectionHeadingStyle3 from '../SectionHeading/SectionHeadingStyle3';
import CtaStyle2 from '../Cta/CtaStyle2';
import AboutStyle6 from '../About/AboutStyle6';
import CardStyle3 from '../Card/CardStyle3';
import { pageTitle } from '../../helpers/PageTitle';

export default function ServiceDetailsPageContent() {
  pageTitle('Content Marketing');
  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />

      {/* Hero Section */}
      <SectionHeadingStyle3
        title="Creating compelling content to attract and engage your audience"
        subTitle="Content Marketing"
        shape="shape_3"
      />
      <Spacing lg="75" md="60" />

      {/* About Section */}
      <div className="cs_service_info">
        <div className="container">
          <div className="row align-items-center cs_gap_y_40">
            <div className="col-lg-6">
              <div
                className="cs_service_info_thumb cs_bg_filed"
                style={{
                  backgroundImage:
                    'url("/images/others/content.jpg")',
                }}
              />
            </div>
            <div className="col-lg-6">
              <div className="row cs_gap_y_40">
                <div className="col-sm-6">
                  <CardStyle3
                    number="01"
                    title="Blog Posts"
                    subTitle="Crafting informative and engaging blog posts to drive traffic and establish authority."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="02"
                    title="Social Media Content"
                    subTitle="Creating shareable and engaging content for various social media platforms."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="03"
                    title="Video Content"
                    subTitle="Producing high-quality video content to captivate and inform your audience."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="04"
                    title="E-books & Whitepapers"
                    subTitle="Developing in-depth resources to educate your audience and generate leads."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="05"
                    title="Infographics"
                    subTitle="Designing visually appealing infographics to convey information effectively."
                  />
                </div>
                <div className="col-sm-6">
                  <CardStyle3
                    number="06"
                    title="Content Strategy"
                    subTitle="Creating a comprehensive content strategy to align with your business goals."
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Spacing lg="150" md="80" />
      <AboutStyle6
        thumbnailSrc="/images/others/content2.jpg"
        title="What you will get from this service?"
        subTitle="Our content marketing services focus on creating compelling content that attracts and engages your target audience. We employ strategic planning and creative execution to ensure your content stands out."
        featureList={[
          'Customized content strategy',
          'High-quality content creation',
          'Multi-platform content distribution',
          'Data-driven performance analysis',
        ]}
        btnText2="Explore Email Marketing"
        btnUrl2="/service/email"
        btnText3="Explore Social Media Marketing"
        btnUrl3="/service/social"
        btnText="More"
        btnUrl="/service"
      />
      <Spacing lg="50" md="80" />

      {/* CTA Section */}
      <div className="cs_height_140 cs_height_lg_70" />
      <CtaStyle2
        title="Is there a specific project or goal <br />that you have in mind?"
        btnText="Send Message"
        btnUrl="/contact"
      />
      <div className="cs_height_150 cs_height_lg_80" />
    </>
  );
}
