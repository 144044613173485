import React, { useState, useRef } from 'react';
import WaterWave from 'react-water-wave';
import Spacing from '../Spacing';
import parse from 'html-react-parser';

export default function VideoModal({ videoSrc, bgUrl, title, titleVariant }) {
  const [toggle, setToggle] = useState(false);
  const videoRef = useRef(null);

  const handleClick = () => {
    setToggle(true);
    setTimeout(() => {
      if (videoRef.current) {
        videoRef.current.play();
      }
    }, 100); // Delay to ensure the video element is rendered
  };

  const handleClose = () => {
    setToggle(false);
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
  };

  return (
    <>
      {title ? (
        <>
          <h2
            className={`cs_video_block_1_title cs_fs_68 text-center mb-0 ${
              titleVariant ? titleVariant : 'text-uppercase'
            }`}
          >
            {parse(title)}
          </h2>
          <Spacing lg="80" md="45" />
        </>
      ) : (
        ''
      )}
      <WaterWave
        className="cs_video_block cs_style_1 cs_bg_filed cs_radius_15 position-relative d-flex justify-content-center align-items-center cs_ripple_activate overflow-hidden"
        imageUrl={bgUrl}
      >
        {() => (
          <span className="cs_hero_video_icon" onClick={handleClick}>
            <svg
              width={80}
              height={80}
              viewBox="0 0 80 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx={40} cy={40} r={40} fill="#FD6219" />
              <path
                d="M60.079 39.9998L30.148 57.4394L30.0104 22.7986L60.079 39.9998Z"
                fill="white"
              />
            </svg>
          </span>
        )}
      </WaterWave>

      {toggle && (
        <div className="cs_video_popup active">
          <div className="cs_video_popup_overlay" onClick={handleClose} />
          <div className="cs_video_popup_content">
            <div className="cs_video_popup_layer" />
            <div className="cs_video_popup_container">
              <div className="cs_video_popup_align">
                <div className="embed-responsive embed-responsive-16by9">
                  <video
                    ref={videoRef}
                    controls
                    className="embed-responsive-item"
                  >
                    <source src={videoSrc} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
              <div className="cs_video_popup_close" onClick={handleClose} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
